import { yupResolver } from '@hookform/resolvers/yup';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
} from '@mui/material';
import { useState } from 'react';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { SettingStatus } from '@/api/DTO/mentalHealthInfo/mentalHealthInfo.interface';
import AppTextField from '@/common/components/form-inputs/AppTextField';
import Close from '@mui/icons-material/Close';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import AppDropDownMenu from '@/common/components/form-inputs/AppDropDownMenu';
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { useBatchUpdateStatusMutation } from '@/api/mentalHealthInfo/mentalHealthApiSlice';
import { ArticlestatusType } from '@/api/enum/common.enum';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';

interface SettingStatusModalProps {
	currentStatus: string | number | undefined;
	setStatusButton?: boolean;
	selectedId: string[];
	setSelectedId?: any;
	articleStatusList: { id: string | number; name: string }[];
}

const SettingStatusModal = (props: SettingStatusModalProps) => {
	const {
		selectedId,
		setSelectedId,
		articleStatusList,
		setStatusButton,
		currentStatus,
	} = props;
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [batchUpdateStatus] = useBatchUpdateStatusMutation();
	const { userRoles } = useGetUser<useGetUserResultType>({
		roleObj: {
			statusRemark: ['statusRemark', 'mentalHealth'],
		},
	});

	const defaultValues: DefaultValues<SettingStatus> = {
		mark: '',
		status: '',
	};

	const formSchema = Yup.object().shape({
		status: Yup.string().required(t('errorMessage.pleaseSelect')),
	});

	const methods = useForm<SettingStatus>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const handleClose = () => {
		methods.reset();
		setOpen(false);
	};

	const onSubmit: SubmitHandler<SettingStatus> = async (data) => {
		const newData: any = {
			articleIds: selectedId,
			mark: data.mark,
			status: { type: data.status },
		};
		const tipTitle =
			Number(data.status) == ArticlestatusType.draft
				? t('snackbar.statusToDraft')
				: Number(data.status) == ArticlestatusType.directorApproval
				? t('snackbar.statusToDirectorApproval')
				: Number(data.status) == ArticlestatusType.supervisorApproval
				? t('snackbar.statusToSupervisorApproval')
				: Number(data.status) == ArticlestatusType.awaitingRelease
				? t('snackbar.statusToAwaitingRelease')
				: Number(data.status) == ArticlestatusType.released
				? t('snackbar.statusToReleased')
				: Number(data.status) == ArticlestatusType.removed
				? t('snackbar.statusToRemoved')
				: '';
		const res = await batchUpdateStatus(newData);
		if ('data' in res) {
			enqueueSnackbar(tipTitle, {
				variant: 'success',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
				autoHideDuration: 3000,
				action: (key) => {
					return <CloseIcon onClick={() => closeSnackbar(key)} />;
				},
			});
			handleClose();
			!setStatusButton && setSelectedId([]);
		}
	};

	return (
		<>
			{setStatusButton ? (
				<Button
					startIcon={<CreateOutlinedIcon />}
					onClick={() => setOpen(true)}
				/>
			) : (
				<Button
					variant='outlined'
					startIcon={<CreateOutlinedIcon />}
					disabled={selectedId.length === 0}
					onClick={() => setOpen(true)}
				>
					{t('button.settingStatus')}
					{selectedId.length > 0 && ` (${selectedId.length})`}
				</Button>
			)}

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth='xs'
				fullWidth
			>
				<DialogTitle variant='h5'>{t('common.settingStatus')}</DialogTitle>
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
				<DialogContent>
					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							<Grid container spacing={2} marginTop={0}>
								<Grid item xs={12}>
									<AppDropDownMenu
										disabledItem={currentStatus}
										control={methods.control}
										name='status'
										label={t('common.status')}
										options={articleStatusList}
										required
									/>
								</Grid>
								{userRoles.statusRemark && (
									<Grid item xs={12}>
										<AppTextField
											control={methods.control}
											name='mark'
											label={t('common.remark') + t('common.optional')}
										/>
									</Grid>
								)}
							</Grid>
						</form>
					</FormProvider>
				</DialogContent>
				<DialogActions>
					<Button
						type='submit'
						variant='contained'
						onClick={methods.handleSubmit(onSubmit)}
					>
						{t('button.confirm')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default SettingStatusModal;
