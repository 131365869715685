import { useState } from 'react';
import { styled } from '@mui/system';
import {
	DataGrid,
	GridSelectionModel,
	GridRowIdGetter,
	GridToolbar,
} from '@mui/x-data-grid';

/* eslint-disable */
interface LocalDataGridProps {
	rows: any[];
	columns: any[];
	rowCount: number;
	getRowId: GridRowIdGetter<any> | undefined;
	onPageChange?: (page: number) => void;
	onPageSizeChange?: (pageSize: number) => void;
	pagination?: boolean;
	NoRowMessage?: JSX.Element;
}
/* eslint-enable */

/**
 * @deprecated This component should not be used anymore. Please use AppDataGrid instead.
 */
export default function LocalDataGrid({
	rows,
	columns,
	rowCount,
	getRowId,
	onPageChange,
	onPageSizeChange,
	pagination,
	NoRowMessage,
}: LocalDataGridProps) {
	const initialPageSize = 20;
	const initialPage = 0;
	const [page, setPage] = useState<number>(initialPage);
	const [pageSize, setPageSize] = useState<number>(initialPageSize);
	const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

	return (
		<StyledDataGrid
			rows={rows}
			columns={columns}
			getRowId={getRowId}
			rowCount={rowCount}
			hideFooter={pagination == null || pagination == true ? false : true}
			page={page}
			onPageChange={(page: number) => {
				setPage(page);
				onPageChange ? onPageChange(page) : () => {};
			}}
			pageSize={pagination ? pageSize : 100}
			onPageSizeChange={(pageSize: number) => {
				setPageSize(pageSize);
				onPageSizeChange ? onPageSizeChange(pageSize) : () => {};
			}}
			pagination={pagination ? pagination : true}
			rowsPerPageOptions={pagination ? [10, 20, 50, 100] : []}
			disableSelectionOnClick
			disableColumnFilter
			disableColumnSelector
			disableDensitySelector
			disableColumnMenu
			autoHeight
			checkboxSelection={false}
			headerHeight={56}
			getRowHeight={() => 'auto'}
			onSelectionModelChange={(newSelectionModel) => {
				setSelectionModel(newSelectionModel);
			}}
			selectionModel={selectionModel}
			components={{
				Toolbar: GridToolbar,
			}}
			componentsProps={{
				toolbar: {
					csvOptions: { disableToolbarButton: true },
					printOptions: { disableToolbarButton: true },
					quickFilterProps: { debounceMs: 250 },
				},
			}}
		/>
	);
}

const StyledDataGrid = styled(DataGrid)(() => ({
	border: 0,
	borderBottom: '1px solid #E0E0E0',
	'& .MuiDataGrid-columnHeaderTitle': {
		fontWeight: 'normal',
	},
	'& .MuiDataGrid-columnHeaders': {
		backgroundColor: '#EEF1F6',
		fontWeight: 'normal',
	},
	'& .MuiDataGrid-columnSeparator': {
		visibility: 'hidden',
	},
	'& .MuiDataGrid-iconButtonContainer button': {
		display: 'none',
	},
	'.MuiDataGrid-cellContent': {
		wordWrap: 'break-word',
		whiteSpace: 'normal',
		padding: '15px 0',
	},
}));
