import {
	Box,
	Button,
	Card,
	CardContent,
	Chip,
	Grid,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Typography,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import SendToMobileOutlinedIcon from '@mui/icons-material/SendToMobileOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useCallback, useEffect, useState } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import AppDataGrid, {
	initialPagination,
} from '@/common/components/dataGrid/AppDataGrid';
import { filterObjectByKeys } from '@/utils/qsHelper';
import { GridColDef } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import AddNonMemberTargetListModal from './AddNonMemberTargetListModal';
import {
	useAddSendSurveyMutation,
	useDeleteSurveyUserMutation,
	useGetSurveyFreeUserPageQuery,
} from '@/api/survey/surveyApiSlice';
import {
	SurveyFreeUserDTO,
	SurveyUserPageFilterReqDTO,
} from '@/api/DTO/survey/survey.interface';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import dayjs from 'dayjs';
import { DISPLAY_CHINESE_DATE_TIME_FORMAT } from '@/utils/dateHelper';
import SurveyUserPageSummaryFilterSection from './SurveyUserPageSummaryFilterSection';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';
import useModal from '@/common/components/ConfirmationDialog/useModal';
import ConfirmationDialog from '@/common/components/ConfirmationDialog/ConfirmationDialog';

export interface NonMemberTargetListDataGrid {
	id: string;
	surveyUserId: string;
	userId: string;
	username: string;
	email: string;
	phone?: string;
	district: string;
	disabled: boolean;
	sendAt: string;
}

export default function NonMemberTargetListCard() {
	const { t } = useTranslation();
	const { surveyId } = useParams();
	const showModal = useModal();

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const { userRoles } = useGetUser<useGetUserResultType>({
		shouldRefetch: true,
		roleObj: {
			read: ['read', 'survey', 'survey-info'],
			edit: ['edit', 'survey', 'survey-info'],
			send: ['send', 'survey'],
		},
	});

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const [selectedUser, setSelectedUser] = useState<string[]>([]);

	const [deleteSurveyUser] = useDeleteSurveyUserMutation();
	const [addSendSurvey] = useAddSendSurveyMutation();

	const handleSendTarget = async () => {
		const response = await addSendSurvey({ surveyUserIds: selectedUser });

		if ('data' in response) {
			enqueueSnackbar(t('snackbar.sendTargetUserSuccess'), {
				variant: 'success',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
				autoHideDuration: 3000,
				action: (key) => {
					return <CloseIcon onClick={() => closeSnackbar(key)} />;
				},
			});
		}
	};

	const handleDeleteTarget = () => {
		showModal((props: any) => (
			<ConfirmationDialog
				{...props}
				showCloseIcon={true}
				title={t('survey.deleteSurveyTarget')}
				titleProps={{ variant: 'h6' }}
				description={
					<Typography variant='body1'>
						<Trans
							i18nKey={'survey.deleteSurveyTargetMsg'}
							values={{ numOfTarget: selectedUser.length }}
						/>
					</Typography>
				}
				cancellationText={''}
				confirmationText={t('button.delete')}
				confirmationButtonProps={{ color: 'error', variant: 'outlined' }}
				onConfirm={async () => {
					const response = await deleteSurveyUser({
						surveyUserIds: selectedUser,
					});
					if ('data' in response) {
						enqueueSnackbar(t('snackbar.deleteSurveyTargetSuccess'), {
							variant: 'success',
							anchorOrigin: { horizontal: 'center', vertical: 'top' },
							autoHideDuration: 3000,
							action: (key) => {
								return <CloseIcon onClick={() => closeSnackbar(key)} />;
							},
						});
						setSelectedUser([]);
						handleClose();
					}
				}}
			/>
		));
	};

	const defaultMemberTargetFilter: SurveyUserPageFilterReqDTO = {
		pagination: initialPagination,
		surveyId: surveyId ?? '',
		loginID: '',
		sortings: [],
	};

	const defaultValues: DefaultValues<SurveyUserPageFilterReqDTO> =
		defaultMemberTargetFilter;

	const [searchFilter, setSearchFilter] = useState<SurveyUserPageFilterReqDTO>(
		defaultMemberTargetFilter
	);

	const methods = useForm<SurveyUserPageFilterReqDTO>({
		defaultValues,
	});

	const transformedSearchParams: SurveyUserPageFilterReqDTO = {
		...defaultMemberTargetFilter,
		...filterObjectByKeys(searchFilter, [
			'pagination',
			'sortings',
			'surveyId',
			'loginID',
		]),
	};

	const {
		data: getSurveyFreeUserPage,
		isSuccess: isGetSurveyFreeUserPageSuccess,
	} = useGetSurveyFreeUserPageQuery(transformedSearchParams, {
		refetchOnMountOrArgChange: true,
	});

	// watch fields change
	useEffect(() => {
		const subscription = methods.watch((value) => {
			const surveyUserPageFilterFormResult =
				value as SurveyUserPageFilterReqDTO;
			setSearchFilter(surveyUserPageFilterFormResult);
		});
		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const transformResponse = useCallback((response?: SurveyFreeUserDTO[]) => {
		if (response == null) return;
		const newResponse: NonMemberTargetListDataGrid[] = [];
		response.forEach((item) => {
			newResponse.push({
				id: item.surveyUserId ?? '-',
				surveyUserId: item.surveyUserId ?? '-',
				userId: item.userId ?? '-',
				username: item.username ?? '-',
				email: item.email,
				phone: item.phone,
				district: item.district ?? '-',
				disabled: item.disabled ?? '-',
				sendAt: item.sendAt
					? dayjs(item.sendAt).format(DISPLAY_CHINESE_DATE_TIME_FORMAT)
					: '-',
			});
		});
		return newResponse;
	}, []);

	const columns: GridColDef[] = [
		{
			field: 'username',
			headerName: t('common.username'),
			flex: 27,
			renderCell: (params) => (
				<Box>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='body2'
						variant='regular'
					>
						{params.row.username}
					</Typography>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='caption'
						color='common.neutral50'
						variant='regular'
					>
						{params.row.phone}
					</Typography>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='caption'
						color='common.neutral50'
						variant='regular'
					>
						{params.row.email}
					</Typography>
				</Box>
			),
		},
		{
			field: 'district',
			headerName: t('common.belongedDistrict'),
			flex: 28,
		},
		{
			field: 'disabled',
			headerName: t('common.status'),
			flex: 28,
			renderCell: (params) => (
				<>
					{params.row.disabled == '0' && (
						<Chip
							label={t('common.normal')}
							color='chipGreen'
							variant='outlined'
						/>
					)}
					{params.row.disabled == '1' && (
						<Chip
							label={t('common.freezed')}
							color='chipRed'
							variant='outlined'
						/>
					)}
				</>
			),
		},
		{
			field: 'sendAt',
			headerName: t('common.lastSentNotiTime'),
			flex: 28,
		},
	];

	return (
		<Grid container alignItems='stretch' columnSpacing={3} marginTop={2}>
			<Grid item xs display='flex'>
				<Card sx={{ width: '100%' }}>
					<CardContent>
						<Box display='flex' justifyContent='space-between'>
							<Typography variant='h5'>
								{t('common.nonMemberTargetList')}
							</Typography>
							{userRoles.send && (
								<Box display='flex' gap={1}>
									<Button
										variant='text'
										startIcon={<SendToMobileOutlinedIcon />}
										disabled={selectedUser.length == 0}
										onClick={handleSendTarget}
									>
										{t('button.sendToDesignatedUsers')}
										{selectedUser.length > 0 && `(${selectedUser.length})`}
									</Button>
									<AddNonMemberTargetListModal />
									{getSurveyFreeUserPage &&
										getSurveyFreeUserPage.data.items.length > 0 && (
											<div>
												<IconButton
													aria-label='more'
													id='more-button'
													aria-controls={open ? 'more-menu' : undefined}
													aria-expanded={open ? 'true' : undefined}
													aria-haspopup='true'
													onClick={handleClick}
												>
													<MoreVertIcon />
												</IconButton>
												<Menu
													id='more-menu'
													MenuListProps={{
														'aria-labelledby': 'more-button',
													}}
													anchorEl={anchorEl}
													open={open}
													onClose={handleClose}
												>
													<MenuItem
														onClick={handleDeleteTarget}
														disabled={selectedUser.length == 0}
													>
														<ListItemIcon>
															<DeleteOutlineOutlinedIcon
																fontSize='small'
																color='error'
															/>
														</ListItemIcon>
														<ListItemText
															primaryTypographyProps={{ color: 'error' }}
														>
															{t('button.deleteTarget')}
														</ListItemText>
													</MenuItem>
												</Menu>
											</div>
										)}
								</Box>
							)}
						</Box>
						<Grid container spacing={3} marginTop={0}>
							<Grid item xs={12}>
								<FormProvider {...methods}>
									{(searchFilter.loginID !== '' ||
										(getSurveyFreeUserPage &&
											getSurveyFreeUserPage.data.items.length > 0)) && (
										<SurveyUserPageSummaryFilterSection />
									)}

									{getSurveyFreeUserPage &&
									getSurveyFreeUserPage.data.items.length > 0 ? (
										<Box marginTop={2}>
											<AppDataGrid
												loading={!isGetSurveyFreeUserPageSuccess}
												rows={
													transformResponse(
														getSurveyFreeUserPage?.data?.items
													) ?? []
												}
												columns={columns}
												rowCount={
													getSurveyFreeUserPage?.data?.pagination.totalItems ??
													0
												}
												getRowId={(row) => row.id}
												checkboxSelection
												selectionModel={selectedUser}
												onSelectionModelChange={(ids) => {
													setSelectedUser(ids.map((id) => String(id)));
												}}
											/>
										</Box>
									) : (
										<Typography variant='body2' color='common.neutral50'>
											{t('common.noNonMemberTarget')}
										</Typography>
									)}
								</FormProvider>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
}
