import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined';
import {
	Badge,
	BadgeProps,
	Button,
	Grid,
	Stack,
	Tab,
	Tabs,
	Typography,
	styled,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AppMultipleDropDownMenu from '@/common/components/form-inputs/AppMultipleDropDownMenu';
import { useRouteMatch } from '@/utils/routeHelper';
import { Link, useLocation } from 'react-router-dom';
import {
	ResourcesCountRes,
	ResourcesInfoFilter,
} from '@/api/DTO/resources/resourcesList.interface';
import { Option } from '@/api/DTO/common.interface';
import { useMemo } from 'react';
import { useResourcesListOptions } from '@/hooks/useResourcesListOptions';
import { CommunityResourcesType } from '@/api/enum/common.enum';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
	'& .MuiBadge-badge': {
		position: 'relative',
		top: 12,
		backgroundColor: '#FFDAD6',
		color: '#FF5449',
	},
	'&.badge-active .MuiBadge-badge': {
		backgroundColor: '#FF5449',
		color: '#fff',
	},
}));

interface FilterSectionProps {
	setSelectedId: any;
	districtsList: Option[];
	typesList: Option[];
	subTypesMap: any;
	methods: any;
	pageCount: ResourcesCountRes;
}

export default function ResourcesSummaryFilterSection(
	prop: FilterSectionProps
) {
	const {
		districtsList,
		setSelectedId,
		typesList,
		subTypesMap,
		methods,
		pageCount,
	} = prop;
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const { control, watch, resetField } = useFormContext<ResourcesInfoFilter>();

	const routeMatch = useRouteMatch(pathname, [
		'/resources/resources-list/drafts',
		'/resources/resources-list/director-approval',
		'/resources/resources-list/supervisor-approval',
		'/resources/resources-list/awaiting-release',
		'/resources/resources-list/released',
		'/resources/resources-list/removed',
	]);
	const currentTab = routeMatch?.pattern?.path;

	const resetFilterRHFState = () => {
		resetField('communityIds');
		resetField('types');
		resetField('subTypeIds');
	};

	const clearAllFilter = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		event.preventDefault();
		resetFilterRHFState();
	};

	// reset button
	const watchCommunityIds = watch('communityIds');
	const watchType = watch('types');
	const watchSubType = watch('subTypeIds');

	const subTypeList = useMemo(() => {
		const arr: any[] = [];
		if (watchType && watchType.length > 0) {
			watchType.forEach((item: string | number) => {
				if (subTypesMap[item] && Array.isArray(subTypesMap[item])) {
					arr.push(...subTypesMap[item]);
				}
			});
		}
		return arr;
	}, [watchType, subTypesMap]);

	const isDistrictDirty =
		Array.isArray(watchCommunityIds) && watchCommunityIds.length !== 0;
	const isTypeDirty = Array.isArray(watchType) && watchType.length !== 0;
	const isSubTypeDirty =
		Array.isArray(watchSubType) && watchSubType.length !== 0;
	const isFilterDirty = isDistrictDirty || isTypeDirty || isSubTypeDirty;

	const tabList = [
		{
			name: 'draft',
			tabText: t('tabs.draft'),
			tabValue: `/resources/resources-list/drafts`,
			tabTo: `/resources/resources-list/drafts`,
			countKey: 'draftCount',
		},
		{
			name: 'director-approval',
			tabText: t('tabs.awaitingDirectorApproval'),
			tabValue: `/resources/resources-list/director-approval`,
			tabTo: `/resources/resources-list/director-approval`,
			countKey: 'secondDraftCount',
		},
		{
			name: 'supervisor-approval',
			tabText: t('tabs.awaitingSupervisorApproval'),
			tabValue: `/resources/resources-list/supervisor-approval`,
			tabTo: `/resources/resources-list/supervisor-approval`,
			countKey: 'thirdDraftCount',
		},
		{
			name: 'awaiting-release',
			tabText: t('tabs.awaitingRelease'),
			tabValue: `/resources/resources-list/awaiting-release`,
			tabTo: `/resources/resources-list/awaiting-release`,
			countKey: 'readyCount',
		},
		{
			name: 'released',
			tabText: t('tabs.released'),
			tabValue: `/resources/resources-list/released`,
			tabTo: `/resources/resources-list/released`,
			countKey: 'publishCount',
		},
		{
			name: 'removed',
			tabText: t('tabs.removed'),
			tabValue: `/resources/resources-list/removed`,
			tabTo: `/resources/resources-list/removed`,
			countKey: 'pullCount',
		},
	];

	const handleTabChange = () => {
		setSelectedId([]);
		methods.reset();
	};
	return (
		<Stack>
			<Tabs
				value={currentTab}
				sx={{ borderBottom: 1, borderColor: 'divider' }}
				onChange={handleTabChange}
			>
				{currentTab &&
					tabList.map((item) => (
						<Tab
							key={item.name}
							label={
								<>
									{Number(pageCount[item.countKey]) > 0 ? (
										<StyledBadge
											badgeContent={pageCount[item.countKey]}
											className={
												currentTab.indexOf(item.name) > -1 ? 'badge-active' : ''
											}
										>
											<Typography variant='body1'>
												&nbsp;{item.tabText}
											</Typography>
										</StyledBadge>
									) : (
										<Typography variant='body1'>{item.tabText}</Typography>
									)}
								</>
							}
							value={item.tabValue}
							to={item.tabTo}
							component={Link}
						/>
					))}
			</Tabs>

			<Grid container spacing={3} alignItems='center' marginTop={0}>
				<Grid item xs={2}>
					<AppMultipleDropDownMenu
						control={control}
						name='communityIds'
						label={t('common.district')}
						options={districtsList}
						size='small'
						emptyArrayIfNoSelection
					/>
				</Grid>
				<Grid item xs={2}>
					<AppMultipleDropDownMenu
						control={control}
						name='types'
						label={t('common.classify')}
						options={typesList}
						size='small'
						emptyArrayIfNoSelection
					/>
				</Grid>
				<Grid item xs={2}>
					<AppMultipleDropDownMenu
						control={control}
						name='subTypeIds'
						label={t('common.subclassify')}
						options={subTypeList}
						size='small'
						emptyArrayIfNoSelection
					/>
				</Grid>

				<Grid item xs>
					<Button
						variant='text'
						startIcon={<RotateLeftOutlinedIcon />}
						disabled={!isFilterDirty}
						onClick={clearAllFilter}
					>
						{t('button.reset')}
					</Button>
				</Grid>
			</Grid>
		</Stack>
	);
}
