import {
	Box,
	Button,
	Card,
	CardContent,
	Chip,
	Divider,
	Grid,
	Stack,
	Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import FullScreenLoader from '../../../../common/components/FullScreenLoader';
import { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DISPLAY_CHINESE_DATE_TIME_FORMAT } from '../../../../utils/dateHelper';
import dayjs from 'dayjs';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import PersonalInfoForm from '../../component/PersonalInfoForm';
import { FormType } from '../../../../api/enum/formType.enum';
import AppFieldView from '../../../../common/components/form-view/AppFieldView';
import EditUsernameModal from '../../../../common/components/modal/EditUsernameModal';
import EditPhoneNumberModal from '../../../../common/components/modal/EditPhoneNumberModal';
import EditEmailModal from '../../../../common/components/modal/EditEmailModal';
import EditBelongedDistrictModal from '../../../../common/components/modal/EditBelongedDistrictModal';
import EditUserTypeModal from '@/common/components/modal/EditUserTypeModal';
import { UserCategory } from '@/api/enum/userCategory.enum';
import {
	useGetFreeUserDetailQuery,
	useGetUserDetailQuery,
} from '@/api/user/userApiSlice';
import { UserType, userTypeOptions } from '@/api/enum/userType.enum';
import { genderOptions } from '@/api/enum/gender.enum';
import { UserCatContext } from '@/context/userCategoryContext';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';
import { UserApplyStatus } from '@/api/enum/userStatus.enum';

export const PropertyIdContext = createContext('');

export default function ViewUserInfoPage() {
	const { t } = useTranslation();
	const { userId } = useParams();

	const { userCat, isApproving } = useContext(UserCatContext);

	const [type, setType] = useState<FormType>(FormType.view);

	const { userRoles } = useGetUser<useGetUserResultType>({
		shouldRefetch: true,
		roleObj: {
			read: ['read', 'user', 'user-info'],
			edit: ['edit', 'user', 'user-info'],
			editUsername: ['edit', 'user', 'username'],
			editPhone: ['edit', 'user', 'phone'],
			editEmail: ['edit', 'user', 'email'],
		},
	});

	let getUserDetailResponse: any, isGetUserDetailLoading;

	if (userCat === UserCategory.ORG) {
		({ data: getUserDetailResponse, isLoading: isGetUserDetailLoading } =
			useGetUserDetailQuery(userId ?? '', {
				refetchOnMountOrArgChange: true,
				skip: !userId || !userRoles?.read,
			}));
	} else if (userCat === UserCategory.FREE) {
		({ data: getUserDetailResponse, isLoading: isGetUserDetailLoading } =
			useGetFreeUserDetailQuery(userId ?? '', {
				refetchOnMountOrArgChange: true,
				skip: !userId || !userRoles?.read,
			}));
	}

	let pageContent;

	if (isGetUserDetailLoading) {
		pageContent = <FullScreenLoader />;
	} else {
		pageContent = (
			<>
				{userRoles?.read && (
					<Grid container alignItems='stretch' columnSpacing={3} marginTop={2}>
						<Grid item style={{ width: '360px' }}>
							<Card>
								<CardContent>
									<Stack
										alignItems='center'
										marginBottom={2}
										sx={{ position: 'relative' }}
									>
										<Chip
											label={
												userTypeOptions.find(
													(option) =>
														option.id == getUserDetailResponse?.data.userType
												)?.name ?? ''
											}
											color={
												getUserDetailResponse?.data?.userType ===
												UserType.PROGRAM_USER
													? 'chipPIRUser'
													: 'chipFilledTertiary'
											}
											size='medium'
											sx={{
												position: 'absolute',
												top: 0,
												right: 0,
											}}
										/>
										<Box
											sx={{
												width: '80px',
												height: '80px',
												borderRadius: '50%',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												marginBottom: '16px',
											}}
										>
											<img
												src={getUserDetailResponse?.data?.avatarUrl}
												alt='avatar'
												width='100%'
												style={{
													aspectRatio: '1/1',
													borderRadius: '50%',
												}}
											/>
										</Box>

										<Typography variant='h6'>
											{getUserDetailResponse?.data?.username}
										</Typography>
									</Stack>
									<Divider />
									<Grid container rowSpacing={2} columnSpacing={1} marginY={1}>
										<Grid item xs={4}>
											<Typography variant='body2'>
												{t('common.creationTime')}
											</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography variant='body2'>
												{getUserDetailResponse?.data?.createdAt
													? dayjs(getUserDetailResponse?.data?.createdAt)
															.locale('zh-hk-my-setting')
															.format(DISPLAY_CHINESE_DATE_TIME_FORMAT)
													: '-'}
											</Typography>
										</Grid>
										<Grid item xs={4}>
											<Typography variant='body2'>
												{t('common.membershipTime')}
											</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography variant='body2'>
												{getUserDetailResponse?.data?.joinedAt
													? dayjs(getUserDetailResponse?.data?.joinedAt)
															.locale('zh-hk-my-setting')
															.format(DISPLAY_CHINESE_DATE_TIME_FORMAT)
													: '-'}
											</Typography>
										</Grid>
										<Grid item xs={4}>
											<Typography variant='body2'>
												{t('common.lastOnlineTime')}
											</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography variant='body2'>
												{getUserDetailResponse?.data?.lastLogin
													? dayjs(getUserDetailResponse?.data?.lastLogin)
															.locale('zh-hk-my-setting')
															.format(DISPLAY_CHINESE_DATE_TIME_FORMAT)
													: '-'}
											</Typography>
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs display='flex'>
							<Card sx={{ width: '100%' }}>
								<CardContent>
									<Typography variant='h5'>
										{t('common.accountInfo')}
									</Typography>
									<Grid container spacing={3} marginTop={0} marginBottom={4}>
										<Grid item xs={6}>
											<AppFieldView
												label={t('common.username')}
												value={getUserDetailResponse?.data?.username}
												editButton={
													isApproving == UserApplyStatus.JOIN_CENTRE ||
													(userRoles.editUsername &&
														!getUserDetailResponse?.data.disabled && (
															<EditUsernameModal
																userId={userId ?? ''}
																username={getUserDetailResponse?.data?.username}
															/>
														))
												}
											/>
										</Grid>
										<Grid item xs={6}>
											<AppFieldView
												label={t('common.phoneNumber')}
												value={getUserDetailResponse?.data?.phone}
												editButton={
													isApproving == UserApplyStatus.JOIN_CENTRE ||
													(userRoles.editPhone &&
														!getUserDetailResponse?.data.disabled && (
															<EditPhoneNumberModal
																userId={userId ?? ''}
																phone={getUserDetailResponse?.data?.phone}
															/>
														))
												}
											/>
										</Grid>
										<Grid item xs={6}>
											<AppFieldView
												label={t('common.email')}
												value={getUserDetailResponse?.data?.email}
												editButton={
													isApproving == UserApplyStatus.JOIN_CENTRE ||
													(userRoles.editEmail &&
														!getUserDetailResponse?.data.disabled && (
															<EditEmailModal
																userId={userId ?? ''}
																email={getUserDetailResponse?.data?.email}
															/>
														))
												}
											/>
										</Grid>
										<Grid item xs={6}>
											<AppFieldView
												label={t('common.belongedDistrict')}
												value={getUserDetailResponse?.data?.district}
												editButton={
													isApproving == UserApplyStatus.JOIN_CENTRE ||
													(userRoles.edit &&
														!getUserDetailResponse?.data.disabled && (
															<EditBelongedDistrictModal
																userId={userId ?? ''}
																districtId={
																	getUserDetailResponse?.data?.districtId
																}
															/>
														))
												}
											/>
										</Grid>
										<Grid item xs={6}>
											<AppFieldView
												label={t('common.userCategory')}
												value={
													userTypeOptions.find(
														(option) =>
															option.id == getUserDetailResponse?.data.userType
													)?.name ?? ''
												}
												editButton={
													isApproving == UserApplyStatus.JOIN_CENTRE ||
													(userRoles.edit &&
														!getUserDetailResponse?.data.disabled &&
														userCat === UserCategory.ORG && (
															<EditUserTypeModal
																userId={userId ?? ''}
																userType={getUserDetailResponse?.data.userType}
																level={getUserDetailResponse?.data.level}
																serviceNumber={
																	getUserDetailResponse?.data?.serviceNumber
																}
																staffId={getUserDetailResponse?.data?.staffId}
															/>
														))
												}
											/>
										</Grid>
										{getUserDetailResponse?.data.userType ==
											UserType.PROGRAM_USER && (
											<>
												<Grid item xs={6}>
													<AppFieldView
														label={t('common.serviceNumber')}
														value={getUserDetailResponse?.data?.serviceNumber}
														editButton={
															isApproving == UserApplyStatus.JOIN_CENTRE ||
															(userRoles.edit &&
																!getUserDetailResponse?.data.disabled && (
																	<EditUserTypeModal
																		userId={userId ?? ''}
																		userType={
																			getUserDetailResponse?.data.userType
																		}
																		level={getUserDetailResponse?.data.level}
																		serviceNumber={
																			getUserDetailResponse?.data?.serviceNumber
																		}
																		staffId={
																			getUserDetailResponse?.data?.staffId
																		}
																	/>
																))
														}
													/>
												</Grid>
												<Grid item xs={6}>
													<AppFieldView
														label={t('common.level')}
														value={getUserDetailResponse?.data?.level.toString()}
														editButton={
															isApproving == UserApplyStatus.JOIN_CENTRE ||
															(userRoles.edit &&
																!getUserDetailResponse?.data.disabled && (
																	<EditUserTypeModal
																		userId={userId ?? ''}
																		userType={
																			getUserDetailResponse?.data.userType
																		}
																		level={getUserDetailResponse?.data.level}
																		serviceNumber={
																			getUserDetailResponse?.data?.serviceNumber
																		}
																		staffId={
																			getUserDetailResponse?.data?.staffId
																		}
																	/>
																))
														}
													/>
												</Grid>
												<Grid item xs={6}>
													<AppFieldView
														label={t('common.staffInCharge')}
														value={getUserDetailResponse?.data?.staff}
														editButton={
															isApproving == UserApplyStatus.JOIN_CENTRE ||
															(userRoles.edit &&
																!getUserDetailResponse?.data.disabled && (
																	<EditUserTypeModal
																		userId={userId ?? ''}
																		userType={
																			getUserDetailResponse?.data.userType
																		}
																		level={getUserDetailResponse?.data.level}
																		serviceNumber={
																			getUserDetailResponse?.data?.serviceNumber
																		}
																		staffId={
																			getUserDetailResponse?.data?.staffId
																		}
																	/>
																))
														}
													/>
												</Grid>
											</>
										)}
									</Grid>
									{(() => {
										switch (type) {
											case FormType.view:
												return (
													<>
														<Box display='flex' justifyContent='space-between'>
															<Typography variant='h5'>
																{t('common.personalInfo')}
															</Typography>
															{isApproving == UserApplyStatus.JOIN_CENTRE ||
																(userRoles.edit &&
																	!getUserDetailResponse?.data.disabled && (
																		<Button
																			startIcon={<CreateOutlinedIcon />}
																			onClick={() => setType(FormType.edit)}
																		>
																			{t('button.edit')}
																		</Button>
																	))}
														</Box>
														<Grid container spacing={3} marginTop={0}>
															<Grid item xs={6}>
																<AppFieldView
																	label={t('common.nickname')}
																	value={getUserDetailResponse?.data?.nickname}
																/>
															</Grid>
															<Grid item xs={6}>
																<AppFieldView
																	label={t('common.gender')}
																	value={
																		genderOptions.find(
																			(option) =>
																				option.id ==
																				getUserDetailResponse?.data?.gender
																		)?.name ?? ''
																	}
																/>
															</Grid>

															<Grid item xs={6}>
																<AppFieldView
																	label={t('common.dateOfBirth')}
																	value={getUserDetailResponse?.data?.birthday}
																/>
															</Grid>
															<Grid item xs={6}>
																<AppFieldView
																	label={t('common.personalizedWords')}
																	value={getUserDetailResponse?.data?.signature}
																/>
															</Grid>
														</Grid>
													</>
												);
											case FormType.edit:
												return (
													<PersonalInfoForm
														type={type}
														setType={setType}
														userId={userId ?? ''}
														nickname={getUserDetailResponse?.data?.nickname}
														signature={getUserDetailResponse?.data?.signature}
														gender={getUserDetailResponse?.data?.gender}
														birthday={getUserDetailResponse?.data?.birthday}
													/>
												);
										}
									})()}
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				)}
			</>
		);
	}

	return <div>{pageContent}</div>;
}
