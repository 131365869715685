export enum CommunityEnum {
	TSEUNG_KWAN_O_SAI_KUNG = 0,
	KOWLOON_CITY = 1,
	KWUN_TONG = 2,
	SHA_TIN_MA_ON_SHAN = 3,
	TAI_PO_NORTH_DISTRICT = 4,
	WONG_TAI_SIN = 5,
	SHAM_SHUI_PO = 6,
	TUNG_CHUNG = 7,
	TUEN_MUN = 8,
	TIN_SHUI_WAI = 9,
	TSUEN_WAN_KWAI_TSING = 10,
	YUEN_LONG = 11,
	YAU_TSIM_MONG = 12,
}

export function getCommunityName(community: CommunityEnum): string {
	switch (community) {
		case CommunityEnum.TSEUNG_KWAN_O_SAI_KUNG:
			return '將軍澳/西貢';
		case CommunityEnum.KOWLOON_CITY:
			return '九龍城';
		case CommunityEnum.KWUN_TONG:
			return '觀塘';
		case CommunityEnum.SHA_TIN_MA_ON_SHAN:
			return '沙田/馬鞍山';
		case CommunityEnum.TAI_PO_NORTH_DISTRICT:
			return '大埔/北區';
		case CommunityEnum.WONG_TAI_SIN:
			return '黃大仙';
		case CommunityEnum.SHAM_SHUI_PO:
			return '深水埗';
		case CommunityEnum.TUNG_CHUNG:
			return '東涌';
		case CommunityEnum.TUEN_MUN:
			return '屯門';
		case CommunityEnum.TIN_SHUI_WAI:
			return '天水圍';
		case CommunityEnum.TSUEN_WAN_KWAI_TSING:
			return '荃葵青';
		case CommunityEnum.YUEN_LONG:
			return '元朗';
		case CommunityEnum.YAU_TSIM_MONG:
			return '油尖旺';
		default:
			return 'Unknown';
	}
}
