import { Box, Typography } from '@mui/material';
import React from 'react';
import { AppFormLabel } from './AppFieldView';

export interface AppFieldViewProps {
	label?: string;
	imageElement?: React.ReactNode;
}

export default function AppFieldView({
	label,
	imageElement,
}: AppFieldViewProps) {
	return (
		<>
			<AppFormLabel>{label}</AppFormLabel>
			{imageElement ? (
				<Box display='flex' justifyContent='space-between'>
					{imageElement}
				</Box>
			) : (
				<Typography variant='body1'>-</Typography>
			)}
		</>
	);
}
