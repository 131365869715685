import { yupResolver } from '@hookform/resolvers/yup';
import { DefaultValues, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Button, Grid, Stack, Typography } from '@mui/material';
import AppTextField from '../../../common/components/form-inputs/AppTextField';
import AppDatePicker from '../../../common/components/form-inputs/AppDatePicker';
import AppRadioGroup from '../../../common/components/form-inputs/AppRadioGroup';
import { FormType } from '../../../api/enum/formType.enum';
import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { UpdateUserPersonal } from '@/api/DTO/user/user.interface';
import { UserCategory } from '@/api/enum/userCategory.enum';
import { closeSnackbar, useSnackbar } from 'notistack';
import { genderOptions } from '@/api/enum/gender.enum';
import {
	useEditFreePersonalMutation,
	useEditOrgPersonalMutation,
} from '@/api/user/userApiSlice';
import { UserCatContext } from '@/context/userCategoryContext';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import useModal from '@/common/components/ConfirmationDialog/useModal';
import ConfirmationDialog from '@/common/components/ConfirmationDialog/ConfirmationDialog';

interface PersonalInfoFormProps extends UpdateUserPersonal {
	type: FormType;
	setType: Dispatch<SetStateAction<FormType>>;
}

export default function PersonalInfoForm({
	type,
	setType,
	userId,
	nickname,
	signature,
	gender,
	birthday,
}: PersonalInfoFormProps) {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const showModal = useModal();

	const { userCat } = useContext(UserCatContext);

	const [editOrgPersonal] = useEditOrgPersonalMutation();
	const [editFreePersonal] = useEditFreePersonalMutation();

	const formSchema = Yup.object().shape({});

	const defaultValues: DefaultValues<UpdateUserPersonal> = {
		userId: userId,
		nickname: nickname,
		signature: signature,
		gender: gender,
		birthday: birthday,
	};

	const { handleSubmit, control } = useForm<UpdateUserPersonal>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const handleClose = () => {
		showModal((props: any) => (
			<ConfirmationDialog
				{...props}
				showCloseIcon={true}
				title={t('common.giveUpEdit')}
				titleProps={{ variant: 'h6' }}
				description={
					<>
						<Typography variant='body1'>{t('common.giveUpMsg')}</Typography>
					</>
				}
				cancellationText={t('button.giveUpFix')}
				confirmationText={t('button.saveAndLeave')}
				confirmationButtonProps={{ type: 'submit', variant: 'contained' }}
				onConfirm={() => handleSubmit(onSubmit)()}
				onCancel={() => setType(FormType.view)}
			/>
		));
	};

	const onSubmit: SubmitHandler<UpdateUserPersonal> = async (data) => {
		if (userCat === UserCategory.ORG) {
			const response = await editOrgPersonal(data);
			if ('data' in response) {
				enqueueSnackbar(t('snackbar.editSaveSuccess'), {
					variant: 'success',
					anchorOrigin: { horizontal: 'center', vertical: 'top' },
					autoHideDuration: 3000,
					action: (key) => {
						return <CloseIcon onClick={() => closeSnackbar(key)} />;
					},
				});
				setType(FormType.view);
			}
		} else {
			const response = await editFreePersonal(data);
			if ('data' in response) {
				enqueueSnackbar(t('snackbar.editSaveSuccess'), {
					variant: 'success',
					anchorOrigin: { horizontal: 'center', vertical: 'top' },
					autoHideDuration: 3000,
					action: (key) => {
						return <CloseIcon onClick={() => closeSnackbar(key)} />;
					},
				});
				setType(FormType.view);
			}
		}
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Typography variant='h5'>{t('common.personalInfo')}</Typography>
				<Grid container spacing={3} marginTop={0} marginBottom={3}>
					<Grid item xs={6}>
						<AppTextField
							control={control}
							name='nickname'
							label={t('common.nickname') + t('common.optional')}
							maxLength={100}
						/>
					</Grid>
					<Grid item xs={6}>
						<Typography variant='body2' color='common.neutral40'>
							{t('common.gender')}
						</Typography>
						<AppRadioGroup
							control={control}
							name='gender'
							row
							options={genderOptions}
						/>
					</Grid>
					<Grid item xs={6}>
						<AppDatePicker
							control={control}
							name='birthday'
							label={t('common.dateOfBirth') + t('common.optional')}
							maximumDate={dayjs().subtract(1, 'day').format()}
						/>
					</Grid>
					<Grid item xs={6}>
						<AppTextField
							control={control}
							name='signature'
							label={t('common.personalizedWords') + t('common.optional')}
							maxLength={100}
							showMaxLength
							multiline
						/>
					</Grid>
				</Grid>
				<Stack spacing='16px'>
					<Grid item xs={12}>
						<Stack direction='row-reverse' spacing='12px'>
							<Button type='submit' variant='contained' color='primary'>
								{t('button.save')}
							</Button>

							<Button variant='text' color='info' onClick={handleClose}>
								{t('button.cancel')}
							</Button>
						</Stack>
					</Grid>
				</Stack>
			</form>
		</>
	);
}
