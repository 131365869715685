import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import {
	FormControlLabel,
	FormControl,
	Radio,
	RadioGroup,
	FormHelperText,
} from '@mui/material';
import { Option } from '../../../api/DTO/common.interface';
import { ReactNode } from 'react';
import { AppFormLabel } from '../form-view/AppFieldView';

interface AppRadioGroupProps {
	required?: boolean;
	label?: string;
	options: Option[];
	disabledOptions?: any[];
	infoPopUp?: ReactNode;
	row?: boolean;
	style?: {
		[key: string]: any;
	};
}

export default function AppRadioGroup<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	label,
	options,
	required,
	disabledOptions,
	infoPopUp,
	row,
	style,
	...props
}: AppRadioGroupProps & UseControllerProps<TFieldValues, TName>) {
	const { field, fieldState } = useController(props);
	const errorMessage = fieldState.error?.message;

	return (
		<>
			<FormControl
				sx={{ padding: '0 1rem', ...style }}
				fullWidth
				variant='standard'
				error={!!errorMessage}
			>
				{label && <AppFormLabel>{label}</AppFormLabel>}
				<RadioGroup {...field} row={row}>
					{options.map((option) => (
						<FormControlLabel
							key={option.id}
							value={option.id}
							control={<Radio />}
							label={option.name}
							disabled={disabledOptions?.includes(option.id)}
						/>
					))}
				</RadioGroup>
				<FormHelperText>{errorMessage}</FormHelperText>
			</FormControl>
		</>
	);
}
