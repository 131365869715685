import {
	BaseQueryFn,
	createApi,
	FetchArgs,
	fetchBaseQuery,
	FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { showModal } from '../common/components/modal/modalSlice';
import { logOut, setCredentials } from '../pages/auth/authSlice';
import { RootState } from '../store';
import { Mutex } from 'async-mutex';
import {
	showSnackbar,
	SnackbarType,
} from '../common/components/snackbar/snackbarSlice';
import {
	hideFullScreenLoader,
	showFullScreenLoader,
} from '../common/components/fullScreenLoader/fullScreenLoaderSlice';
import { AuthLoginResDTO } from './DTO/auth.interface';
import { SuccessResponse } from './DTO/common.interface';

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_BASE_URL,
	prepareHeaders: (headers, { getState }) => {
		const token = (getState() as RootState).auth.accessToken;
		if (token) {
			headers.set('authorization', `Bearer ${token}`);
			if (!headers.has('Accept-Language')) {
				headers.set('Accept-Language', `en-US`);
			}
			return headers;
		}
	},
});

const reAuthBaseQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_BASE_URL,
	credentials: 'include',
});

const baseQueryWithReauth: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError,
	{ disableFullScreenLoader?: boolean; showSnackbarError?: boolean }
> = async (args, api, extraOptions) => {
	await mutex.waitForUnlock();

	if (!extraOptions?.disableFullScreenLoader)
		api.dispatch(showFullScreenLoader());

	let result = await baseQuery(args, api, extraOptions);

	if (!extraOptions?.disableFullScreenLoader)
		api.dispatch(hideFullScreenLoader());

	if (result?.error?.status === 401 || result?.error?.status === 403) {
		if (!mutex.isLocked()) {
			const release = await mutex.acquire();
			try {
				const refreshResult = await reAuthBaseQuery(
					'/web/auth/refresh',
					api,
					extraOptions
				);

				const refreshTokenResponse =
					refreshResult?.data as SuccessResponse<AuthLoginResDTO> | null;

				if (refreshTokenResponse?.success) {
					// store the new token
					api.dispatch(
						setCredentials({
							accessToken: (
								refreshResult?.data as SuccessResponse<AuthLoginResDTO>
							).data.accessToken,
						})
					);
					result = await baseQuery(args, api, extraOptions);
				} else {
					api.dispatch(logOut());
				}
			} finally {
				release();
			}
		} else {
			await mutex.waitForUnlock();
			result = await baseQuery(args, api, extraOptions);
		}
	} else if (result?.error && api.endpoint != 'refresh') {
		const err = result?.error;
		let errMsg: string;

		if (isFetchBaseQueryError(err)) {
			if ('error' in err) {
				errMsg = err.error;
			} else {
				errMsg = isErrorWithMessage(err.data)
					? err.data.message
					: 'Unknown Error';
			}
		} else {
			errMsg = 'Unknown Error';
		}

		if (extraOptions && extraOptions.showSnackbarError) {
			api.dispatch(
				showSnackbar({
					snackbarType: SnackbarType.MESSAGE,
					snackbarProps: {
						sx: {
							'.MuiSnackbarContent-root': {
								backgroundColor: 'error.main',
							},
						},
						anchorOrigin: { vertical: 'top', horizontal: 'center' },
						message: errMsg,
					},
				})
			);
		} else {
			api.dispatch(
				showModal({
					modalType: 'API_ERROR',
					modalProps: {
						title: 'ERROR',
						content: errMsg,
					},
				})
			);
		}
	}
	return result;
};

function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
	return typeof error === 'object' && error != null && 'status' in error;
}

function isErrorWithMessage(
	error: unknown
): error is { success: boolean; message: string; data?: unknown } {
	return (
		typeof error === 'object' &&
		error != null &&
		'message' in error &&
		typeof error.message === 'string'
	);
}
export const apiSlice = createApi({
	baseQuery: baseQueryWithReauth,
	tagTypes: [
		'GetMe',
		'StaffInfo',
		'UserFreeze',
		'CommunityResourcesInfo',
		'CommunityResourcesLogsCount',
		'CommunityResourcesList',
		'CommunityResourcesCount',
		'CommunityResourcesLogsList',
		'MentalHealthList',
		'MentalHealthInfo',
		'MentalHealthLogsList',
		'MentalHealthCount',
		'MentalHealthLogsCount',
		'ApplicationList',
		'ApplicationCount',
		'MemberTarget',
		'NonMemberTarget',
		'Survey',
		'MemberUser',
		'NonMemberUser',
		'Voucher',
		'UserStat',
		'UserStatExp',
		'UserSteps',
	],
	endpoints: () => ({}),
});
