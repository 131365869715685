export const userStatusOptions = [
	{
		id: false,
		name: '正常',
	},
	{
		id: true,
		name: '已凍結',
	},
];

export enum UserApplyStatus {
	JOIN_MEMBER = 1,
	QUIT_MEMBER = 2,
	JOIN_CENTRE = 3,
	QUIT_CENTRE = 4,
}
