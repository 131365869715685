import {
	Alert,
	Box,
	Button,
	Card,
	CardContent,
	Chip,
	IconButton,
	Snackbar,
	Stack,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';
import AppDataGridHeaderWithSorting from '../../../common/components/dataGrid/AppDataGridHeaderWithSorting';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import NonMemberUserSummaryFilterSection from './components/NonMemberUserSummaryFilterSection';
import AppDataGrid, {
	initialPagination,
} from '../../../common/components/dataGrid/AppDataGrid';
import qs from 'qs';
import {
	filterObjectByKeys,
	parseSummarySearchParams,
} from '../../../utils/qsHelper';
import { DISPLAY_CHINESE_DATE_TIME_FORMAT } from '../../../utils/dateHelper';
import dayjs from 'dayjs';
import AddIcon from '@mui/icons-material/Add';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CloseIcon from '@mui/icons-material/Close';
import {
	FreeUserPageDTO,
	FreeUserPageItem,
} from '@/api/DTO/user/user.interface';
import { useGetFreeUserPageQuery } from '@/api/user/userApiSlice';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';

export interface FreeUserPageDataGrid {
	id: string;
	userId: string;
	email: string;
	lastLogin: string;
	username: string;
	phone: string;
	nickname: string;
	district: string;
	disabled: boolean;
}

export default function NonMemberUserSummaryPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [searchParams, setSearchParams] = useSearchParams();
	const [isInitializedForm, setIsInitializedForm] = useState<boolean>(false);

	const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);

	const { userRoles } = useGetUser<useGetUserResultType>({
		shouldRefetch: true,
		roleObj: {
			read: ['read', 'user', 'user-list'],
			create: ['create', 'user'],
		},
	});

	const defaultFreeUserPageFilter: FreeUserPageDTO = {
		pagination: initialPagination,
		sortings: [],
		loginID: '',
	};

	const defaultValues: DefaultValues<FreeUserPageDTO> =
		defaultFreeUserPageFilter;

	// watch serach params
	useEffect(() => {
		if (!isInitializedForm) {
			methods.reset(transformedSearchParams, {
				keepDefaultValues: true,
			});
			setIsInitializedForm(true);
		}
	}, [searchParams]);

	const methods = useForm<FreeUserPageDTO>({
		defaultValues,
	});

	const transformedSearchParams: FreeUserPageDTO = {
		...defaultFreeUserPageFilter,
		...filterObjectByKeys(parseSummarySearchParams(searchParams), [
			'pagination',
			'sortings',
			'loginID',
			'statuses',
			'districtIds',
		]),
	};

	const {
		data: freeUserPageSummary,
		isSuccess: isGetFreeUserPageSummarySuccess,
	} = useGetFreeUserPageQuery(transformedSearchParams, {
		refetchOnMountOrArgChange: true,
		skip: !userRoles.read,
	});

	// watch fields change
	useEffect(() => {
		const subscription = methods.watch((value) => {
			const freeUserPageFilterFormResult = value as FreeUserPageDTO;

			// append to url to reload page
			setSearchParams(qs.stringify(freeUserPageFilterFormResult));
		});
		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const handleSnackbarClose = () => {
		setIsSnackbarOpen(false);
	};

	const transformResponse = useCallback((response?: FreeUserPageItem[]) => {
		if (response == null) return;
		const newResponse: FreeUserPageDataGrid[] = [];
		response.forEach((item) => {
			newResponse.push({
				id: item.userId,
				userId: item.userId,
				email: item.email,
				lastLogin: item.lastLogin,
				username: item.username,
				nickname: item.nickname ?? '-',
				phone: item.phone,
				district: item.district,
				disabled: item.disabled,
			});
		});
		return newResponse;
	}, []);

	const DataGridHeader = (params: GridColumnHeaderParams) => {
		return (
			<AppDataGridHeaderWithSorting
				headerName={params.colDef.headerName}
				sortingsPropertyName={params.colDef.field}
			/>
		);
	};

	const columns: GridColDef[] = [
		{
			field: 'username',
			headerName: t('common.username'),
			flex: 25,
			renderHeader: DataGridHeader,
			renderCell: (params) => (
				<Box>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='body2'
						variant='regular'
					>
						{params.row.username}
					</Typography>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='caption'
						color='common.neutral50'
						variant='regular'
					>
						{params.row.phone}
					</Typography>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='caption'
						color='common.neutral50'
						variant='regular'
					>
						{params.row.email}
					</Typography>
				</Box>
			),
		},
		{
			field: 'nickname',
			headerName: t('common.nickname'),
			flex: 20,
		},
		{
			field: 'district',
			headerName: t('common.belongedDistrict'),
			flex: 20,
		},
		{
			field: 'disabled',
			headerName: t('common.status'),
			flex: 10,
			renderCell: (params) => (
				<Chip
					label={params.row.disabled ? t('common.freezed') : t('common.normal')}
					color={params.row.disabled ? 'chipRed' : 'chipGreen'}
					variant='outlined'
				/>
			),
		},
		{
			field: 'lastLogin',
			headerName: t('common.lastOnlineTime'),
			flex: 20,
			renderCell: (params) => (
				<Typography variant='regular'>
					{params.row.lastLogin
						? dayjs(params.row.lastLogin)
								.locale('zh-hk-my-setting')
								.format(DISPLAY_CHINESE_DATE_TIME_FORMAT)
						: '-'}
				</Typography>
			),
		},
		{
			field: 'edit',
			headerName: '',
			width: 68,
			renderCell: (params) => (
				<Link
					to={`/users/non-member-user/${params.id}`}
					state={{
						propertySummarySearchParams: qs.stringify(transformedSearchParams),
					}}
				>
					<IconButton color='primary'>
						<VisibilityOutlinedIcon />
					</IconButton>
				</Link>
			),
		},
	];

	return (
		<>
			<Snackbar
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				open={isSnackbarOpen}
			>
				<Alert
					severity='success'
					action={<CloseIcon onClick={handleSnackbarClose} />}
				>
					{t('snackbar.createMemberUserSuccess')}
				</Alert>
			</Snackbar>
			<Box display='flex' justifyContent='space-between'>
				<Typography variant='h4'>{t('common.nonMemberUser')}</Typography>
				<Stack direction={'row'} alignItems={'center'} spacing={3}>
					{userRoles.create && (
						<Button
							variant='contained'
							startIcon={<AddIcon />}
							onClick={() =>
								navigate(`/users/non-member-user/add`, {
									state: {
										propertySummarySearchParams: qs.stringify(
											transformedSearchParams
										),
									},
								})
							}
							style={{ alignItems: 'center' }}
						>
							{t('button.addNonMemberUser')}
						</Button>
					)}
				</Stack>
			</Box>
			<Card sx={{ marginTop: 3 }}>
				{userRoles.read && (
					<CardContent>
						<FormProvider {...methods}>
							<NonMemberUserSummaryFilterSection />
							<Box marginTop={2}>
								<AppDataGrid
									loading={!isGetFreeUserPageSummarySuccess}
									rows={
										transformResponse(freeUserPageSummary?.data?.items) ?? []
									}
									columns={columns}
									rowCount={
										freeUserPageSummary?.data?.pagination.totalItems ?? 0
									}
									getRowId={(row) => row.id}
								/>
							</Box>
						</FormProvider>
					</CardContent>
				)}
			</Card>
		</>
	);
}
