import { yupResolver } from '@hookform/resolvers/yup';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	Grid,
	Stack,
	Typography,
} from '@mui/material';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import AppDatePicker from '../../../common/components/form-inputs/AppDatePicker';
import AppDropDownMenu from '@/common/components/form-inputs/AppDropDownMenu';
import AppRadioGroup from '../../../common/components/form-inputs/AppRadioGroup';
import AppTextField, {
	InputType,
} from '../../../common/components/form-inputs/AppTextField';
import { useEffect, useState } from 'react';
import { AddUserDTO } from '@/api/DTO/user/user.interface';
import { UserType, userTypeOptions } from '@/api/enum/userType.enum';
import { Gender, genderOptions } from '@/api/enum/gender.enum';
import { useSelector } from 'react-redux';
import { selectMyInfo } from '@/pages/auth/authSlice';
import { useGetCentreDistrictsQuery } from '@/api/area/areaApiSlice';
import { useGetStaffListQuery } from '@/api/staff/staffApiSlice';
import { useAddOrgUserMutation } from '@/api/user/userApiSlice';
import { useNavigate } from 'react-router-dom';
import { closeSnackbar, useSnackbar } from 'notistack';
import AppPasswordTextField from '@/common/components/form-inputs/AppPasswordTextField';
import dayjs from 'dayjs';
import { PASSWORD_PATTERN } from '@/common/constants/pattern';
import CloseIcon from '@mui/icons-material/Close';

export default function CreateMemberUserPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const [needConfirm, setNeedConfirm] = useState(false);

	const myInfo = useSelector(selectMyInfo);

	const { data: centreDistricts } = useGetCentreDistrictsQuery(
		myInfo?.centre || '',
		{
			refetchOnMountOrArgChange: true,
			skip: !myInfo?.centre,
		}
	);

	const { data: getStaffList } = useGetStaffListQuery(undefined, {
		refetchOnMountOrArgChange: true,
	});

	const [addOrgUser] = useAddOrgUserMutation();

	const defaultValues: DefaultValues<AddUserDTO> = {
		username: '',
		password: '',
		phone: '',
		email: '',
		nickname: '',
		districtId: '',
		userType: UserType.NORMAL_USER,
		level: 1,
		serviceNumber: '',
		staffId: '',
		gender: Gender.OTHER,
		birthday: '',
		signature: '',
	};

	const formSchema = Yup.object().shape(
		{
			username: Yup.string()
				.nullable()
				.min(4, t('errorMessage.usernameMin4'))
				.matches(/^[a-zA-Z0-9_.-]*$/, t('errorMessage.usernameOnlyAllow')),
			password: Yup.string()
				.nullable()
				.matches(PASSWORD_PATTERN, {
					excludeEmptyString: true,
					message: t('errorMessage.passwordMin6'),
				}),
			districtId: Yup.string().required(t('errorMessage.pleaseSelect')),
			phone: Yup.string()
				.nullable()
				.when('email', {
					is: (email: string) => !email,
					then: (schema) => schema.required(t('errorMessage.emailOrPhone')),
				})
				.test(
					'phone',
					() => t('errorMessage.phone'),
					(phone) => phone?.length == 8 || phone?.length == 0
				),
			email: Yup.string()
				.nullable()
				.when('phone', {
					is: (phone: string) => !phone,
					then: (schema) => schema.required(t('errorMessage.emailOrPhone')),
				})
				.email(t('errorMessage.email')),
			serviceNumber: Yup.string()
				.nullable()
				.when('userType', {
					is: UserType.PROGRAM_USER,
					then: (schema) => schema.required(t('errorMessage.required')),
				}),
			staffId: Yup.string()
				.nullable()
				.when('userType', {
					is: UserType.PROGRAM_USER,
					then: (schema) => schema.required(t('errorMessage.pleaseSelect')),
				}),
		},
		[['phone', 'email']]
	);

	const methods = useForm<AddUserDTO>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	useEffect(() => {
		const subscription = methods.watch((value, { name }) => {
			if (!needConfirm) setNeedConfirm(true);
		});

		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const watchUserType = methods.watch('userType');
	const watchPhone = methods.watch('phone');
	const watchEmail = methods.watch('email');

	useEffect(() => {
		if (watchEmail) methods.trigger('phone');
	}, [watchEmail]);

	useEffect(() => {
		if (watchPhone) methods.trigger('email');
	}, [watchPhone]);

	useEffect(() => {
		if (watchUserType === '1') {
			methods.setValue('userType', 1);
		} else if (watchUserType === '2') {
			methods.setValue('userType', 2);
		}
	}, [watchUserType]);

	const onSubmit: SubmitHandler<AddUserDTO> = async (data) => {
		const response = await addOrgUser(data);
		if ('data' in response) {
			enqueueSnackbar(t('snackbar.createMemberUserSuccess'), {
				variant: 'success',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
				autoHideDuration: 3000,
				action: (key) => {
					return <CloseIcon onClick={() => closeSnackbar(key)} />;
				},
			});
			navigate(-1);
		}
	};

	return (
		<>
			<Typography variant='h4'>{t('common.createMemberUser')}</Typography>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<Grid container alignItems='stretch' columnSpacing={3} marginTop={3}>
						<Grid item style={{ width: '360px' }}>
							<Card>
								<CardContent>
									<Stack alignItems='center'>
										<Box
											sx={{
												backgroundColor: 'grey.400',
												width: '80px',
												height: '80px',
												borderRadius: '50%',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												marginBottom: '16px',
											}}
										>
											<AccountCircleOutlinedIcon
												sx={{
													width: '60px',
													height: '60px',
													color: 'white',
												}}
											/>
										</Box>
										<Typography color='common.neutral70' variant='h6'>
											{t('common.newUser')}
										</Typography>
									</Stack>
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs display='flex'>
							<Card sx={{ width: '100%' }}>
								<CardContent>
									<Stack>
										<Typography variant='h5'>
											{t('common.accountInfo')}
										</Typography>
										<Grid container spacing={3} marginTop={0} marginBottom={3}>
											<Grid item xs={6}>
												<AppTextField
													control={methods.control}
													name='username'
													label={t('common.username') + t('common.optional')}
													maxLength={20}
												/>
											</Grid>
											<Grid item xs={6}>
												<AppPasswordTextField
													control={methods.control}
													name='password'
													label={t('common.password') + t('common.optional')}
													maxLength={40}
												/>
											</Grid>
											<Grid item xs={12}>
												<Typography color='text.secondary'>
													{t('hint.atLeastOneContact')}
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<AppTextField
													control={methods.control}
													name='phone'
													inputType={InputType.number}
													label={t('common.phoneNumber') + t('common.optional')}
													maxLength={8}
												/>
											</Grid>
											<Grid item xs={6}>
												<AppTextField
													control={methods.control}
													name='email'
													label={t('common.email') + t('common.optional')}
													maxLength={50}
												/>
											</Grid>
											<Grid item xs={6}>
												<AppDropDownMenu
													control={methods.control}
													name='districtId'
													label={t('common.belongedDistrict')}
													options={centreDistricts?.data ?? []}
													required
												/>
											</Grid>
											<Grid item xs={6}>
												<Typography variant='body2' color='common.neutral40'>
													{t('common.userCategory')}
												</Typography>

												<AppRadioGroup
													control={methods.control}
													name='userType'
													row
													options={userTypeOptions}
												/>
											</Grid>
											{watchUserType == UserType.PROGRAM_USER && (
												<>
													<Grid item xs={6}>
														<AppTextField
															control={methods.control}
															name='serviceNumber'
															label={t('common.serviceNumber')}
															maxLength={31}
														/>
													</Grid>
													<Grid item xs={6}>
														<Typography
															variant='body2'
															color='common.neutral40'
														>
															{t('common.level')}
														</Typography>
														<AppRadioGroup
															control={methods.control}
															name='level'
															row
															options={[
																{ id: 1, name: '1' },
																{ id: 2, name: '2' },
															]}
														/>
													</Grid>
													<Grid item xs={6}>
														<AppDropDownMenu
															control={methods.control}
															name='staffId'
															label={t('common.staffInCharge')}
															options={getStaffList?.data ?? []}
															required
														/>
													</Grid>
												</>
											)}
										</Grid>
										<Typography variant='h5'>
											{t('common.personalInfo')}
										</Typography>
										<Grid container spacing={3} marginTop={0} marginBottom={3}>
											<Grid item xs={6}>
												<AppTextField
													control={methods.control}
													name='nickname'
													label={t('common.nickname') + t('common.optional')}
													maxLength={100}
												/>
											</Grid>
											<Grid item xs={6}>
												<Typography variant='body2' color='common.neutral40'>
													{t('common.gender')}
												</Typography>
												<AppRadioGroup
													control={methods.control}
													name='gender'
													row
													options={genderOptions}
												/>
											</Grid>
											<Grid item xs={6}>
												<AppDatePicker
													control={methods.control}
													name='birthday'
													label={t('common.dateOfBirth') + t('common.optional')}
													maximumDate={dayjs().subtract(1, 'day').format()}
												/>
											</Grid>
											<Grid item xs={6}>
												<AppTextField
													control={methods.control}
													name='signature'
													label={
														t('common.personalizedWords') + t('common.optional')
													}
													maxLength={100}
													showMaxLength
													multiline
												/>
											</Grid>
										</Grid>
									</Stack>
								</CardContent>
								<CardActions sx={{ justifyContent: 'end' }}>
									<Button
										variant='text'
										onClick={() => {
											navigate(-1);
										}}
									>
										{t('button.cancel')}
									</Button>
									<Button
										type='submit'
										variant='contained'
										disabled={!needConfirm}
									>
										{t('button.save')}
									</Button>
								</CardActions>
							</Card>
						</Grid>
					</Grid>
				</form>
			</FormProvider>
		</>
	);
}
