import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import AppFieldView from '../../../../common/components/form-view/AppFieldView';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import DirectionsWalkOutlinedIcon from '@mui/icons-material/DirectionsWalkOutlined';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import EditNumOfCoinModal from '../../../../common/components/modal/EditNumOfCoinModal';
import AppDataGrid, {
	initialPagination,
} from '../../../../common/components/dataGrid/AppDataGrid';
import {
	filterObjectByKeys,
	parseSummarySearchParams,
} from '../../../../utils/qsHelper';
import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { API_DATE_FORMAT, INVALID_DATE } from '../../../../utils/dateHelper';
import { GridColDef } from '@mui/x-data-grid';
import qs from 'qs';
import UserStatSummaryFilterSection from './components/UserStatSummaryFilterSection';
import EditNumOfStepModal from '../../../../common/components/modal/EditNumOfStepModal';
import EditCurrentExpModal from '../../../../common/components/modal/EditCurrentExpModal';
import ViewUploadedImagesModal from '../../../../common/components/modal/ViewUploadedImagesModal';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';
import { useGetUserStatisticsQuery } from '@/api/statistics/statApiSlice';
import FullScreenLoader from '@/common/components/FullScreenLoader';
import { useFetchUserStepsQuery } from '@/api/health/healthApiSlice';
import { FetchUserStepReqDTO } from '@/api/DTO/health/heath.interface';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export default function ViewUserStatPage() {
	const { t } = useTranslation();
	const { userId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const [isInitializedForm, setIsInitializedForm] = useState<boolean>(false);

	const { userRoles } = useGetUser<useGetUserResultType>({
		shouldRefetch: true,
		roleObj: {
			read: ['read', 'user', 'user-stat'],
			editCoin: ['edit', 'user', 'coin'],
			editExp: ['edit', 'user', 'exp'],
			editWalkStep: ['edit', 'user', 'walk-step'],
		},
	});

	const defaultFetchUserStepFilter: FetchUserStepReqDTO = {
		pagination: initialPagination,
		sortings: [],
		userId: userId ?? '',
		start: dayjs().subtract(30, 'day').format(API_DATE_FORMAT),
		end: dayjs().format(API_DATE_FORMAT),
	};

	const formSchema = Yup.object().shape({
		start: Yup.string()
			.required(t('errorMessage.required'))
			.test(
				'required',
				t('errorMessage.date'),
				(value?: string) => value !== INVALID_DATE
			)
			.test(
				'endDateShouldSameOrBeforeToday',
				t('errorMessage.dateShouldSameOrBeforeToday'),
				(value?: string) =>
					dayjs(value).isSameOrBefore(dayjs().format(API_DATE_FORMAT))
			),
		end: Yup.string()
			.required(t('errorMessage.required'))
			.test(
				'required',
				t('errorMessage.date'),
				(value?: string) => value != INVALID_DATE
			)
			.test(
				'endDateShouldSameOrBeforeToday',
				t('errorMessage.dateShouldSameOrBeforeToday'),
				(value?: string) =>
					dayjs(value).isSameOrBefore(dayjs().format(API_DATE_FORMAT))
			)
			.when('start', (start: any, schema) => {
				return schema.test(
					'endDateShouldSameOrGreaterStartDate',
					() => t('errorMessage.endDateShouldSameOrGreaterStartDate'),
					(value) => {
						console.log(start);
						return start != '' && start != INVALID_DATE
							? dayjs(value).isSameOrAfter(dayjs(start), 'day')
							: true;
					}
				);
			}),
	});

	const defaultValues: DefaultValues<FetchUserStepReqDTO> =
		defaultFetchUserStepFilter;
	const methods = useForm<any>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	// watch serach params
	useEffect(() => {
		if (!isInitializedForm) {
			methods.reset(transformedSearchParams, {
				keepDefaultValues: true,
			});
			setIsInitializedForm(true);
		}
	}, [searchParams]);

	const transformedSearchParams: FetchUserStepReqDTO = {
		...defaultFetchUserStepFilter,
		...filterObjectByKeys(parseSummarySearchParams(searchParams), [
			'pagination',
			'sortings',
			'start',
			'end',
		]),
	};

	const {
		data: userStat,
		isSuccess: isGetUserStatSuccess,
		isLoading: isGetUserStatLoading,
		isError: isGetUserStatError,
	} = useGetUserStatisticsQuery(userId ?? '', {
		refetchOnMountOrArgChange: true,
	});
	const { data: userStepsSummary, isSuccess: isUserStepsSummarySuccess } =
		useFetchUserStepsQuery(transformedSearchParams, {
			refetchOnMountOrArgChange: true,
		});

	// watch fields change
	useEffect(() => {
		const subscription = methods.watch((value) => {
			const propertyFilterFormResult = value as FetchUserStepReqDTO;

			// validate form and continue if form is valid
			methods.handleSubmit(() => {
				// append to url to reload page
				setSearchParams(qs.stringify(propertyFilterFormResult));
			})();
		});
		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const transformResponse = useCallback((response?: any[]) => {
		if (response == null) return;
		const newResponse: any[] = [];
		response.forEach((item) => {
			newResponse.push({
				date: item.date,
				steps: item.steps,
			});
		});
		return newResponse;
	}, []);

	const columns: GridColDef[] = [
		{
			field: 'date',
			headerName: t('common.date'),
			minWidth: 200,
		},
		{
			field: 'steps',
			headerName: t('common.step'),
			flex: 1,
			minWidth: 200,
		},
		{
			field: 'edit',
			headerName: '',
			width: 68,
			renderCell: (params) => (
				<>
					{userRoles.editWalkStep &&
						params.row.date !== dayjs().format(API_DATE_FORMAT) && (
							<EditNumOfStepModal
								userId={userId ?? ''}
								originalSteps={params.row.steps}
								date={params.row.date}
							/>
						)}
				</>
			),
		},
	];

	const iconStyle = { color: '#FFB950' };
	let pageContent;

	if (isGetUserStatLoading) {
		pageContent = <FullScreenLoader />;
	} else if (isGetUserStatSuccess) {
		pageContent = (
			<>
				{userRoles.read && (
					<Grid container alignItems='stretch' columnSpacing={3} marginTop={2}>
						<Grid item xs={6}>
							<Card>
								<CardContent>
									<Box display='flex' gap='8px' alignItems='center'>
										<RocketLaunchOutlinedIcon sx={iconStyle} />
										<Typography variant='h5'>
											{t('stat.coinAndExpRecord')}
										</Typography>
									</Box>
									<Grid container rowSpacing={2} columnSpacing={4}>
										<Grid item xs={6}>
											<AppFieldView
												label={t('stat.numOfCoins')}
												value={userStat?.data?.coin?.toString() ?? ''}
												editButton={
													userRoles.editCoin && (
														<EditNumOfCoinModal
															userId={userId ?? ''}
															coins={userStat?.data?.coin ?? 0}
														/>
													)
												}
											/>
										</Grid>
										<Grid item xs={6}>
											<AppFieldView
												label={t('stat.currentExp')}
												value={`${userStat?.data?.expPortion.toLocaleString()} `}
												subValue={`/ ${userStat?.data?.expAll.toLocaleString()} (等級${
													userStat?.data?.level
												})`}
												editButton={
													userRoles.editExp && (
														<EditCurrentExpModal userId={userId ?? ''} />
													)
												}
											/>
										</Grid>
									</Grid>
									<Box
										display='flex'
										gap='8px'
										alignItems='center'
										marginTop={3}
									>
										<FeedOutlinedIcon sx={iconStyle} />
										<Typography variant='h5'>
											{t('stat.readingMentalHealthInfoRecord')}
										</Typography>
									</Box>
									<Grid container rowSpacing={2} columnSpacing={1}>
										<Grid item xs={6}>
											<AppFieldView
												label={t('stat.numOfReadingArticle')}
												value={userStat?.data?.articleRead?.toString() ?? ''}
											/>
										</Grid>
									</Grid>
									<Box
										display='flex'
										gap='8px'
										alignItems='center'
										marginTop={3}
									>
										<MapOutlinedIcon sx={iconStyle} />
										<Typography variant='h5'>
											{t('stat.regionalInfoRecord')}
										</Typography>
									</Box>
									<Grid container rowSpacing={2} columnSpacing={4}>
										<Grid item xs={6}>
											<AppFieldView
												label={t('stat.numOfUploadedPictures')}
												value={`${userStat?.data?.checkInCount}`}
												subValue={`/${userStat?.data?.buildingCount}`}
											/>
										</Grid>
										<Grid item xs={6}>
											<AppFieldView
												label={t('stat.numOfViewedCommunityInfo')}
												value={`${userStat?.data?.infoCount}`}
											/>
										</Grid>
										<Grid item xs={12}>
											<Typography variant='caption'>
												{t('stat.listOfUploadingPictures')}
											</Typography>
											<Grid container spacing={4}>
												{userStat?.data?.checkInThumbnails &&
													userStat?.data?.checkInThumbnails.map(
														(image: any, index: number) => {
															if (
																index ===
																userStat?.data?.checkInThumbnails?.length - 1
															) {
																return (
																	<Grid item xs={4} key={index}>
																		<ViewUploadedImagesModal
																			userId={userId ?? ''}
																		>
																			<Box
																				key={index}
																				position='relative'
																				width='100%'
																			>
																				<img
																					key={index}
																					src={image}
																					alt={image}
																					width='100%'
																					style={{
																						maxHeight: '100px',
																						borderRadius: '5px',
																						objectFit: 'cover',
																						filter: 'brightness(50%)',
																					}}
																				/>
																				<Box
																					position='absolute'
																					top='50%'
																					left='50%'
																					color='white'
																					style={{
																						transform: 'translate(-50%, -50%)',
																					}}
																				>
																					{t('common.viewAll')}
																				</Box>
																			</Box>
																		</ViewUploadedImagesModal>
																	</Grid>
																);
															} else {
																return (
																	<Grid
																		key={index}
																		item
																		xs={4}
																		position='relative'
																	>
																		<img
																			key={index}
																			src={image}
																			alt={image}
																			width='100%'
																			style={{
																				maxHeight: '100px',
																				borderRadius: '5px',
																				objectFit: 'cover',
																			}}
																		/>
																	</Grid>
																);
															}
														}
													)}
											</Grid>
										</Grid>
									</Grid>
									<Box
										display='flex'
										gap='8px'
										alignItems='center'
										marginTop={3}
									>
										<StorefrontOutlinedIcon sx={iconStyle} />
										<Typography variant='h5'>
											{t('stat.petAndVirtualPropsRecord')}
										</Typography>
									</Box>
									<Grid container rowSpacing={2} columnSpacing={4}>
										<Grid item xs={6}>
											<AppFieldView
												label={t('stat.numOfPetsPurchased')}
												value={userStat?.data?.mascotCount?.toString() ?? ''}
											/>
										</Grid>
										<Grid item xs={6}>
											<AppFieldView
												label={t('stat.numOfVirtualPropsPurchased')}
												value={userStat?.data?.itemCount?.toString() ?? ''}
											/>
										</Grid>
									</Grid>
									<Box
										display='flex'
										gap='8px'
										alignItems='center'
										marginTop={3}
									>
										<LocalOfferOutlinedIcon sx={iconStyle} />
										<Typography variant='h5'>
											{t('stat.redemptionVoucherRecord')}
										</Typography>
									</Box>
									<Grid container rowSpacing={2} columnSpacing={4}>
										<Grid item xs={6}>
											<AppFieldView
												label={t('stat.numOfExchangesReceived')}
												value={
													userStat?.data?.voucherRewardCount?.toString() ?? ''
												}
											/>
										</Grid>
										<Grid item xs={6}>
											<AppFieldView
												label={t('stat.numOfExchangesExchanged')}
												value={
													userStat?.data?.voucherRedeemCount?.toString() ?? ''
												}
											/>
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={6}>
							<Card>
								<CardContent>
									<Box display='flex' gap='8px' alignItems='center'>
										<DirectionsWalkOutlinedIcon sx={iconStyle} />
										<Typography variant='h5'>
											{t('stat.walkingRecord')}
										</Typography>
									</Box>
									<FormProvider {...methods}>
										<form>
											<Grid
												container
												rowSpacing={2}
												columnSpacing={4}
												marginTop={0}
												marginBottom={2}
											>
												<Grid item xs={12} display='flex'>
													<UserStatSummaryFilterSection />
												</Grid>
												<Grid item xs={6} display='flex'>
													<Grid item xs={6}>
														<Typography variant='caption' color={'#515255'}>
															{t('stat.totalWalkingStep')}
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<Typography variant='body1'>
															{userStepsSummary?.data.totalSteps.toLocaleString()}{' '}
															步
														</Typography>
													</Grid>
												</Grid>
											</Grid>
											<AppDataGrid
												loading={!isUserStepsSummarySuccess}
												rows={
													transformResponse(userStepsSummary?.data?.items) ?? []
												}
												columns={columns}
												rowCount={
													userStepsSummary?.data?.pagination.totalItems ?? 0
												}
												getRowId={(row) => row.date}
												columnHeadersBackgroundColor='transparent'
											/>
										</form>
									</FormProvider>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				)}
			</>
		);
	} else if (isGetUserStatError) {
		pageContent = <div></div>;
	}

	return <div>{pageContent}</div>;
}
