import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import MentalHealthContentInfo from './MentalHealthContentInfo';
import { useTranslation } from 'react-i18next';
import {
	FileInfo,
	MentalHealthInfo,
} from '@/api/DTO/mentalHealthInfo/mentalHealthInfo.interface';
import { Box, Button, Typography } from '@mui/material';
import { FormType } from '@/api/enum/formType.enum';
import { useState } from 'react';
import { cloneDeep } from 'lodash';
import { useUpdateArticlesMutation } from '@/api/mentalHealthInfo/mentalHealthApiSlice';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmationDialog from '@/common/components/ConfirmationDialog/ConfirmationDialog';
import useModal from '@/common/components/ConfirmationDialog/useModal';

interface Props {
	formData: any;
	setType: any;
	articleTypeList?: { id: string; name: string }[];
	districtsOptions?: { id: string; name: string }[];
}

const SettingMentalHealthContentInfo = (props: Props) => {
	const { formData, articleTypeList, setType, districtsOptions } = props;
	const showModal = useModal();
	const { t } = useTranslation();
	const [updateArticles] = useUpdateArticlesMutation();
	const [pdfUrl, setPdfUrl] = useState<FileInfo | null>(null);
	const [audioUrl, setAudioUrl] = useState<FileInfo | null>(null);
	const [thumbnailUrl, setThumbnailUrl] = useState<FileInfo | null>(null);
	const [images, setImages] = useState<FileInfo[] | null>(null);

	const formSchema = Yup.object().shape({
		title: Yup.string()
			.required(t('errorMessage.required'))
			.max(20, t('errorMessage.max20', { max: 20 })),
		registrationLink: Yup.string().when('linkAttached', (linkAttached) => {
			if (linkAttached[0]) {
				return Yup.string().required(t('errorMessage.required'));
			} else {
				return Yup.string().notRequired();
			}
		}),
		thumbnail: Yup.object()
			.nullable()
			.test(
				'required',
				t('errorMessage.pleaseUpload'),
				(value) => value !== undefined && value !== null
			),
		images: Yup.array()
			.nullable()
			.when('type', {
				is: (type: string | number) => type === '1' || type === 1,
				then: (schema) => {
					return schema
						.min(1, t('errorMessage.pleaseUpload'))
						.required(t('errorMessage.pleaseUpload'));
				},
			}),
		pdf: Yup.string()
			.nullable()
			.when('type', {
				is: (type: string | number) => type === '2' || type === 2,
				then: (schema) => schema.required(t('errorMessage.pleaseUpload')),
			}),
		videoLink: Yup.string()
			.nullable()
			.when('type', {
				is: (type: string | number) => type === '3' || type === 3,
				then: (schema) =>
					schema
						.required(t('errorMessage.required'))
						.matches(
							/http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w-]*)(&(amp;)?[\w?=]*)?/,
							t('errorMessage.invalidYoutubeLink')
						),
			}),
		audio: Yup.string()
			.nullable()
			.when('type', {
				is: (type: string | number) => type === '4' || type === 4,
				then: (schema) => schema.required(t('errorMessage.pleaseUpload')),
			}),
		content: Yup.string().required(t('errorMessage.required')),
	});
	const defaultValues: DefaultValues<MentalHealthInfo> = {
		// type: formData?.type ?? '', // 格式
		...formData,
	};
	const methods = useForm<MentalHealthInfo>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});
	const onSubmit: SubmitHandler<MentalHealthInfo> = (data: any) => {
		showModal((props: any) => (
			<ConfirmationDialog
				{...props}
				showCloseIcon={true}
				title={t('mentalHealthInfo.editMentalHealth')}
				titleProps={{ variant: 'h6' }}
				description={
					<>
						<Typography variant='body1'>
							{t('mentalHealthInfo.isEditMentalHealth')}
						</Typography>
					</>
				}
				cancellationText={t('button.cancel')}
				confirmationText={t('button.sure')}
				confirmationButtonProps={{ type: 'submit', variant: 'contained' }}
				onConfirm={async () => {
					const newData = cloneDeep(data);
					newData.district = {
						type: data.district,
					};
					data.pdf && (newData.pdf = pdfUrl);
					data.audio && (newData.audio = audioUrl);
					!data.linkAttached && (newData.registrationLink = '');
					const res = await updateArticles(newData);
					if ('data' in res) {
						enqueueSnackbar(t('snackbar.successful'), {
							variant: 'success',
							anchorOrigin: { horizontal: 'center', vertical: 'top' },
							autoHideDuration: 3000,
							action: (key) => {
								return <CloseIcon onClick={() => closeSnackbar(key)} />;
							},
						});
						setType(FormType.view);
					}
				}}
			/>
		));
	};

	const handleClose = () => {
		showModal((props: any) => (
			<ConfirmationDialog
				{...props}
				showCloseIcon={true}
				title={t('communityResources.giveUpFix')}
				titleProps={{ variant: 'h6' }}
				description={
					<>
						<Typography variant='body1'>
							{t('communityResources.sureGiveUpFix')}
						</Typography>
						<Typography variant='body1'>
							{t('communityResources.fixAllReset')}
						</Typography>
					</>
				}
				cancellationText={t('button.giveUpFix')}
				confirmationText={t('button.saveAndLeave')}
				confirmationButtonProps={{ type: 'submit', variant: 'contained' }}
				onConfirm={() => methods.handleSubmit(onSubmit)()}
				onCancel={() => setType(FormType.view)}
			/>
		));
	};

	return (
		<FormProvider {...methods}>
			<form onSubmit={methods.handleSubmit(onSubmit)}>
				<MentalHealthContentInfo
					categoryId={formData.category}
					formData={formData}
					methods={methods}
					articleTypeList={articleTypeList}
					pdfUrl={pdfUrl}
					setPdfUrl={setPdfUrl}
					audioUrl={audioUrl}
					setAudioUrl={setAudioUrl}
					thumbnailUrl={thumbnailUrl}
					setThumbnailUrl={setThumbnailUrl}
					images={images}
					setImages={setImages}
					districtsOptions={districtsOptions || []}
				/>
				<Box sx={{ display: 'flex', justifyContent: 'end' }}>
					<Button onClick={handleClose}>{t('button.cancel')}</Button>
					<Button type='submit' variant='contained'>
						{t('button.save')}
					</Button>
				</Box>
			</form>
		</FormProvider>
	);
};

export default SettingMentalHealthContentInfo;
