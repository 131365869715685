import { yupResolver } from '@hookform/resolvers/yup';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	Grid,
	Stack,
	Typography,
} from '@mui/material';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import AppDatePicker from '../../../common/components/form-inputs/AppDatePicker';
import AppDropDownMenu from '../../../common/components/form-inputs/AppDropDownMenu';
import AppRadioGroup from '../../../common/components/form-inputs/AppRadioGroup';
import AppTextField, {
	InputType,
} from '../../../common/components/form-inputs/AppTextField';
import { useNavigate } from 'react-router-dom';
import { closeSnackbar, useSnackbar } from 'notistack';
import { AddFreeUserDTO } from '@/api/DTO/user/user.interface';
import { UserType } from '@/api/enum/userType.enum';
import { Gender, genderOptions } from '@/api/enum/gender.enum';
import { useAddFreeUserMutation } from '@/api/user/userApiSlice';
import { useGetCentreDistrictsQuery } from '@/api/area/areaApiSlice';
import AppPasswordTextField from '@/common/components/form-inputs/AppPasswordTextField';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { PASSWORD_PATTERN } from '@/common/constants/pattern';
import CloseIcon from '@mui/icons-material/Close';
import { Centre } from '@/api/enum/centre.enum';

export default function CreateNonMemberUserPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const [needConfirm, setNeedConfirm] = useState(false);

	const { data: freeDistricts } = useGetCentreDistrictsQuery(Centre.SHARE, {
		refetchOnMountOrArgChange: true,
	});

	const [addFreeUser] = useAddFreeUserMutation();

	const defaultValues: DefaultValues<AddFreeUserDTO> = {
		username: '',
		password: '',
		phone: '',
		email: '',
		districtId: '',
		userType: UserType.NORMAL_USER,
		nickname: '',
		gender: Gender.OTHER,
		birthday: '',
		signature: '',
	};

	const formSchema = Yup.object().shape(
		{
			username: Yup.string()
				.nullable()
				.min(4, t('errorMessage.usernameMin4'))
				.matches(/^[a-zA-Z0-9_.-]*$/, t('errorMessage.usernameOnlyAllow')),
			password: Yup.string()
				.nullable()
				.matches(PASSWORD_PATTERN, {
					excludeEmptyString: true,
					message: t('errorMessage.passwordMin6'),
				}),
			districtId: Yup.string().required(t('errorMessage.pleaseSelect')),
			phone: Yup.string()
				.nullable()
				.when('email', {
					is: (email: string) => !email || !watchEmail,
					then: (schema) => schema.required(t('errorMessage.emailOrPhone')),
				})
				.test(
					'phone',
					() => t('errorMessage.phone'),
					(phone) => phone?.length == 8 || phone?.length == 0
				),
			email: Yup.string()
				.nullable()
				.when('phone', {
					is: (phoneNum: string) => !phoneNum || !watchPhone,
					then: (schema) => schema.required(t('errorMessage.emailOrPhone')),
				})
				.email(t('errorMessage.email')),
			serviceNumber: Yup.string()
				.nullable()
				.when('userType', {
					is: true,
					then: (schema) => schema.required(t('errorMessage.required')),
				}),
			staffId: Yup.string()
				.nullable()
				.when('userType', {
					is: true,
					then: (schema) => schema.required(t('errorMessage.pleaseSelect')),
				}),
		},
		[['phone', 'email']]
	);

	const methods = useForm<AddFreeUserDTO>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});
	useEffect(() => {
		const subscription = methods.watch((value, { name }) => {
			if (!needConfirm) setNeedConfirm(true);
		});

		return () => subscription.unsubscribe();
	}, [methods.watch]);
	const watchPhone = methods.watch('phone');
	const watchEmail = methods.watch('email');

	useEffect(() => {
		if (watchEmail || watchPhone) methods.trigger(['phone', 'email']);
	}, [watchEmail, watchPhone]);

	const onSubmit: SubmitHandler<AddFreeUserDTO> = async (data) => {
		const response = await addFreeUser(data);
		if ('data' in response) {
			enqueueSnackbar(t('snackbar.createNonMemberUserSuccess'), {
				variant: 'success',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
				autoHideDuration: 3000,
				action: (key) => {
					return <CloseIcon onClick={() => closeSnackbar(key)} />;
				},
			});
			navigate(-1);
		}
	};

	return (
		<>
			<Typography variant='h4'>{t('common.createNonMemberUser')}</Typography>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<Grid container alignItems='stretch' columnSpacing={3} marginTop={3}>
						<Grid item style={{ width: '360px' }}>
							<Card>
								<CardContent>
									<Stack alignItems='center'>
										<Box
											sx={{
												backgroundColor: 'grey.400',
												width: '80px',
												height: '80px',
												borderRadius: '50%',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												marginBottom: '16px',
											}}
										>
											<AccountCircleOutlinedIcon
												sx={{
													width: '60px',
													height: '60px',
													color: 'white',
												}}
											/>
										</Box>
										<Typography color='common.neutral70' variant='h6'>
											{t('common.newUser')}
										</Typography>
									</Stack>
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs display='flex'>
							<Card sx={{ width: '100%' }}>
								<CardContent>
									<Stack>
										<Typography variant='h5'>
											{t('common.accountInfo')}
										</Typography>
										<Grid container spacing={3} marginTop={0} marginBottom={3}>
											<Grid item xs={6}>
												<AppTextField
													control={methods.control}
													name='username'
													label={t('common.username') + t('common.optional')}
													maxLength={20}
												/>
											</Grid>
											<Grid item xs={6}>
												<AppPasswordTextField
													control={methods.control}
													name='password'
													label={t('common.password') + t('common.optional')}
													maxLength={40}
												/>
											</Grid>
											<Grid item xs={12}>
												<Typography color='text.secondary'>
													{t('hint.atLeastOneContact')}
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<AppTextField
													control={methods.control}
													name='phone'
													inputType={InputType.number}
													label={t('common.phoneNumber') + t('common.optional')}
													maxLength={8}
												/>
											</Grid>
											<Grid item xs={6}>
												<AppTextField
													control={methods.control}
													name='email'
													label={t('common.email') + t('common.optional')}
													maxLength={50}
												/>
											</Grid>
											<Grid item xs={6}>
												<AppDropDownMenu
													control={methods.control}
													name='districtId'
													label={t('common.belongedDistrict')}
													options={freeDistricts?.data ?? []}
													required
												/>
											</Grid>
											<Grid item xs={6}>
												<Typography variant='body2' color='common.neutral40'>
													{t('common.userCategory')}
												</Typography>
												<AppRadioGroup
													control={methods.control}
													name='userType'
													row
													options={[
														{
															id: UserType.NORMAL_USER,
															name: t('common.normalMember'),
														},
													]}
												/>
											</Grid>
										</Grid>
										<Typography variant='h5'>
											{t('common.personalInfo')}
										</Typography>
										<Grid container spacing={3} marginTop={0} marginBottom={3}>
											<Grid item xs={6}>
												<AppTextField
													control={methods.control}
													name='nickname'
													label={t('common.nickname') + t('common.optional')}
													maxLength={100}
												/>
											</Grid>
											<Grid item xs={6}>
												<Typography variant='body2' color='common.neutral40'>
													{t('common.gender')}
												</Typography>
												<AppRadioGroup
													control={methods.control}
													name='gender'
													row
													options={genderOptions}
												/>
											</Grid>
											<Grid item xs={6}>
												<AppDatePicker
													control={methods.control}
													name='birthday'
													label={t('common.dateOfBirth') + t('common.optional')}
													maximumDate={dayjs().subtract(1, 'day').format()}
												/>
											</Grid>
											<Grid item xs={6}>
												<AppTextField
													control={methods.control}
													name='signature'
													label={
														t('common.personalizedWords') + t('common.optional')
													}
													maxLength={100}
													showMaxLength
													multiline
												/>
											</Grid>
										</Grid>
									</Stack>
								</CardContent>
								<CardActions sx={{ justifyContent: 'end' }}>
									<Button
										variant='text'
										onClick={() => {
											navigate(-1);
										}}
									>
										{t('button.cancel')}
									</Button>
									<Button
										type='submit'
										variant='contained'
										disabled={!needConfirm}
									>
										{t('button.save')}
									</Button>
								</CardActions>
							</Card>
						</Grid>
					</Grid>
				</form>
			</FormProvider>
		</>
	);
}
