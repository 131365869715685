import { GridColDef } from '@mui/x-data-grid';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import LocalDataGrid from '../../../common/components/dataGrid/LocalDataGrid';
import { Card, CardContent, Typography } from '@mui/material';
import { useGetVouchersQuery } from '../../../api/shop/shopApiSlice';

export default function MemberRewardSummaryPage() {
	const { t } = useTranslation();
	const { data: vouchersData } = useGetVouchersQuery();

	const transformResponse = useCallback((response?: any[]) => {
		if (response == null) return;
		return response.map((item, index) => ({
			id: index,
			name: item.name,
			level: item.level,
		}));
	}, []);

	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: t('common.name'),
			flex: 50,
			sortable: false,
		},
		{
			field: 'level',
			headerName: t('item.rewardLevel'),
			flex: 50,
			sortable: false,
			renderCell: (params) => (
				<>
					{t('item.level')}
					{params.row.level}
				</>
			),
		},
	];

	return (
		<>
			<Typography variant='h4'>{t('item.programMemberReward')}</Typography>

			<Card sx={{ marginTop: 3 }}>
				<CardContent>
					<LocalDataGrid
						rows={transformResponse(vouchersData?.data) ?? []}
						columns={columns}
						rowCount={vouchersData?.data?.length ?? 0}
						getRowId={(row) => row.id}
						pagination={false}
					/>
				</CardContent>
			</Card>
		</>
	);
}
