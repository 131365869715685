import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import {
	Link,
	useParams,
	Outlet,
	useLocation,
	useNavigate,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from '../../../utils/routeHelper';
import {
	Alert,
	AlertTitle,
	Button,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Stack,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useEffect, useState } from 'react';
import ExportReportModal from '../../../common/components/modal/ExportReportModal';
import SendSingleNotificationModal from '../../../common/components/modal/SendSingleNotificationModal';
import JoinMembershipModal from '../../../common/components/modal/JoinMembershipModal';
import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined';
import { UserCategory } from '@/api/enum/userCategory.enum';
import { UserCatContext } from '@/context/userCategoryContext';
import { closeSnackbar, useSnackbar } from 'notistack';
import {
	useDeleteFreeUserMutation,
	useDeleteFreeUserPasswordMutation,
	useFreezeFreeUserMutation,
	useGetFreeUserDetailQuery,
	useUnfreezeFreeUserMutation,
} from '@/api/user/userApiSlice';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';
import { useGetCentreDistrictsQuery } from '@/api/area/areaApiSlice';
import { useSelector } from 'react-redux';
import { selectMyInfo } from '@/pages/auth/authSlice';
import CloseIcon from '@mui/icons-material/Close';
import FullScreenLoader from '@/common/components/FullScreenLoader';
import useModal from '@/common/components/ConfirmationDialog/useModal';
import ConfirmationDialog from '@/common/components/ConfirmationDialog/ConfirmationDialog';

function NonMemberUserTabs({ currentTab }: { currentTab?: string }) {
	const { t } = useTranslation();
	const { userId } = useParams();

	return (
		<>
			{currentTab && (
				<Tabs value={currentTab}>
					<Tab
						label={
							<Typography variant='body1'>{t('tabs.userInfo')}</Typography>
						}
						value='/users/non-member-user/:userId/info'
						to={`/users/non-member-user/${userId}/info`}
						component={Link}
					/>
					<Tab
						label={<Typography variant='body1'>{t('tabs.stat')}</Typography>}
						value='/users/non-member-user/:userId/stat'
						to={`/users/non-member-user/${userId}/stat`}
						component={Link}
					/>
					<Tab
						label={
							<Typography variant='body1'>
								{t('tabs.notificationHistory')}
							</Typography>
						}
						value='/users/non-member-user/:userId/noti-history'
						to={`/users/non-member-user/${userId}/noti-history`}
						component={Link}
					/>
				</Tabs>
			)}
		</>
	);
}

export default function NonMemberUserContainer() {
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const { userId } = useParams();
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const myInfo = useSelector(selectMyInfo);

	const { userRoles } = useGetUser<useGetUserResultType>({
		shouldRefetch: true,
		roleObj: {
			editPassword: ['edit', 'user', 'password'],
			export: ['export', 'user'],
			send: ['send', 'user'],
			centre: ['centre', 'user'],
			freeze: ['freeze', 'user'],
			unfreeze: ['unfreeze', 'user'],
			delete: ['delete', 'user'],
		},
	});

	const {
		data: getUserDetailResponse,
		isLoading: isGetUserDetailLoading,
		isSuccess: isGetUserDetailSuccess,
		isError: isGetUserDetailError,
	} = useGetFreeUserDetailQuery(userId ?? '', {
		refetchOnMountOrArgChange: true,
	});

	const [freezed, setFreezed] = useState(false);

	useEffect(() => {
		if (isGetUserDetailSuccess)
			setFreezed(getUserDetailResponse?.data.disabled);
	}, [isGetUserDetailSuccess]);

	// You need to provide the routes in descendant order.
	// This means that if you have nested routes like:
	// users, users/new, users/edit.
	// Then the order should be ['users/add', 'users/edit', 'users'].
	const routeMatch = useRouteMatch(pathname, [
		'/users/non-member-user/:userId/info',
		'/users/non-member-user/:userId/stat',
		'/users/non-member-user/:userId/noti-history',
	]);

	const currentTab = routeMatch?.pattern?.path;

	const { data: getCentreDistricts } = useGetCentreDistrictsQuery(
		myInfo?.centre || '',
		{
			refetchOnMountOrArgChange: true,
			skip: !myInfo?.centre,
		}
	);

	const [deleteFreeUser] = useDeleteFreeUserMutation();
	const [deleteFreeUserPassword] = useDeleteFreeUserPasswordMutation();
	const [freezeFreeUser] = useFreezeFreeUserMutation();
	const [unfreezeFreeUser] = useUnfreezeFreeUserMutation();

	// more menu

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const showModal = useModal();

	const handleDeleteUser = () => {
		showModal((props: any) => (
			<ConfirmationDialog
				{...props}
				showCloseIcon={true}
				title={t('common.deleteUser')}
				titleProps={{ variant: 'h6' }}
				description={
					<Typography variant='body1' sx={{ whiteSpace: 'pre-line' }}>
						{t('user.deleteUserMsg')}
					</Typography>
				}
				cancellationText={''}
				confirmationText={t('button.delete')}
				confirmationButtonProps={{ color: 'error', variant: 'outlined' }}
				onConfirm={async () => {
					const response = await deleteFreeUser(userId || '');
					if ('data' in response) {
						enqueueSnackbar(t('snackbar.deleteUserSuccess'), {
							variant: 'success',
							anchorOrigin: { horizontal: 'center', vertical: 'top' },
							autoHideDuration: 3000,
							action: (key) => {
								return <CloseIcon onClick={() => closeSnackbar(key)} />;
							},
						});
						navigate('/users/non-member-user');
					}
				}}
			/>
		));
	};

	const handleDeleteUserPassword = () => {
		showModal((props: any) => (
			<ConfirmationDialog
				{...props}
				showCloseIcon={true}
				title={t('common.removePassword')}
				titleProps={{ variant: 'h6' }}
				description={
					<Typography variant='body1' sx={{ whiteSpace: 'pre-line' }}>
						{t('user.removePasswordMsg')}
					</Typography>
				}
				cancellationText={''}
				confirmationText={t('button.remove')}
				confirmationButtonProps={{ color: 'primary', variant: 'contained' }}
				onConfirm={async () => {
					const response = await deleteFreeUserPassword(userId || '');
					if ('data' in response) {
						enqueueSnackbar(t('snackbar.removeUserPasswordSuccess'), {
							variant: 'success',
							anchorOrigin: { horizontal: 'center', vertical: 'top' },
							autoHideDuration: 3000,
							action: (key) => {
								return <CloseIcon onClick={() => closeSnackbar(key)} />;
							},
						});
					}
				}}
			/>
		));
	};

	const handleFreezeUser = () => {
		showModal((props: any) => (
			<ConfirmationDialog
				{...props}
				showCloseIcon={true}
				title={t('common.freezeUser')}
				titleProps={{ variant: 'h6' }}
				description={
					<Typography variant='body1' sx={{ whiteSpace: 'pre-line' }}>
						{t('user.freezeUserMsg')}
					</Typography>
				}
				cancellationText={''}
				confirmationText={t('button.freezed')}
				confirmationButtonProps={{ color: 'error', variant: 'outlined' }}
				onConfirm={async () => {
					const response = await freezeFreeUser(userId || '');
					if ('data' in response) {
						enqueueSnackbar(t('snackbar.freezeUserSuccess'), {
							variant: 'success',
							anchorOrigin: { horizontal: 'center', vertical: 'top' },
							autoHideDuration: 3000,
							action: (key) => {
								return <CloseIcon onClick={() => closeSnackbar(key)} />;
							},
						});
						setFreezed(true);
					}
				}}
			/>
		));
	};

	const handleUnfreezeUser = () => {
		showModal((props: any) => (
			<ConfirmationDialog
				{...props}
				showCloseIcon={true}
				title={t('common.unfreezeUser')}
				titleProps={{ variant: 'h6' }}
				description={
					<Typography variant='body1' sx={{ whiteSpace: 'pre-line' }}>
						{t('user.unfreezeUserMsg')}
					</Typography>
				}
				cancellationText={''}
				confirmationText={t('button.unfreeze')}
				confirmationButtonProps={{ color: 'error', variant: 'outlined' }}
				onConfirm={async () => {
					const response = await unfreezeFreeUser(userId || '');
					if ('data' in response) {
						enqueueSnackbar(t('snackbar.unfreezeUserSuccess'), {
							variant: 'success',
							anchorOrigin: { horizontal: 'center', vertical: 'top' },
							autoHideDuration: 3000,
							action: (key) => {
								return <CloseIcon onClick={() => closeSnackbar(key)} />;
							},
						});
						setFreezed(false);
					}
				}}
			/>
		));
	};

	let pageContent;

	if (isGetUserDetailLoading) {
		pageContent = <FullScreenLoader />;
	} else if (isGetUserDetailSuccess) {
		pageContent = (
			<>
				<UserCatContext.Provider value={{ userCat: UserCategory.FREE }}>
					<Box sx={{ width: '100%' }}>
						<Stack
							direction={'row'}
							justifyContent={'space-between'}
							spacing={3}
						>
							<Typography variant='h4'>{t('common.userDetail')}</Typography>
							<Stack direction={'row'} spacing={3}>
								{/* temporary remove */}
								{/* {userRoles.export && (
									<ExportReportModal userId={userId ?? ''} />
								)} */}
								{userRoles.send && (
									<SendSingleNotificationModal userId={userId ?? ''} />
								)}
								<div>
									<IconButton
										aria-label='more'
										id='more-button'
										aria-controls={open ? 'more-menu' : undefined}
										aria-expanded={open ? 'true' : undefined}
										aria-haspopup='true'
										onClick={handleClick}
									>
										<MoreVertIcon />
									</IconButton>
									<Menu
										id='more-menu'
										MenuListProps={{
											'aria-labelledby': 'more-button',
										}}
										anchorEl={anchorEl}
										open={open}
										onClose={handleClose}
									>
										<JoinMembershipModal
											userId={userId ?? ''}
											disabled={!userRoles.centre}
											joinDistrictsList={getCentreDistricts?.data ?? []}
										/>
										<MenuItem
											onClick={handleDeleteUserPassword}
											disabled={!userRoles.editPassword}
										>
											<ListItemIcon>
												<KeyOutlinedIcon fontSize='small' />
											</ListItemIcon>
											<ListItemText>{t('button.removePassword')}</ListItemText>
										</MenuItem>
										<MenuItem
											onClick={handleFreezeUser}
											disabled={freezed || !userRoles.freeze}
										>
											<ListItemIcon>
												<DoDisturbOnOutlinedIcon fontSize='small' />
											</ListItemIcon>
											<ListItemText>{t('button.freezeUser')}</ListItemText>
										</MenuItem>
										<MenuItem
											onClick={handleDeleteUser}
											disabled={!userRoles.delete}
										>
											<ListItemIcon sx={{ color: 'error.main' }}>
												<DeleteOutlineOutlinedIcon fontSize='small' />
											</ListItemIcon>
											<ListItemText sx={{ color: 'error.main' }}>
												{t('button.deleteUser')}
											</ListItemText>
										</MenuItem>
									</Menu>
								</div>
							</Stack>
						</Stack>

						{freezed && (
							<Alert
								severity='warning'
								sx={{ marginTop: 2 }}
								action={
									<Button
										startIcon={<RotateLeftOutlinedIcon />}
										variant='outlined'
										color='warning'
										onClick={handleUnfreezeUser}
									>
										{t('button.unfreezeUser')}
									</Button>
								}
							>
								<AlertTitle sx={{ margin: 0 }}>
									{t('user.freezedUserMsg')}
								</AlertTitle>
							</Alert>
						)}
						<NonMemberUserTabs currentTab={currentTab} />
						<Outlet />
					</Box>
				</UserCatContext.Provider>
			</>
		);
	} else if (isGetUserDetailError) {
		pageContent = <div></div>;
	}

	return <div>{pageContent}</div>;
}
