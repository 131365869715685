import { Option } from '../DTO/common.interface';

export enum Centre {
	MHAHK = 'MHAHK',
	NLPRA = 'NLPRA',
	SHARE = 'SHARE',
}

export const centreOptions: Option[] = [
	{
		id: Centre.MHAHK,
		name: '香港心理衞生會',
	},
	{
		id: Centre.NLPRA,
		name: '新生精神康復會',
	},
];
