import { apiSlice } from '../apiSlice';
import {
	OutCentreFilter,
	applicationDetailItem,
	applicationListItem,
	applyDTO,
	pageCountDTO,
	pageCountItem,
} from '../DTO/user/application.interface';
import { SuccessResponse, Paginated } from '../DTO/common.interface';

export const staffApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getApplicationInfo: builder.query<
			SuccessResponse<applicationDetailItem>,
			string
		>({
			query: (transferId) => ({
				url: `/web/approval/${transferId}`,
			}),
		}),

		getApplicationList: builder.query<
			SuccessResponse<Paginated<applicationListItem>>,
			OutCentreFilter
		>({
			query: (body) => ({
				url: `/web/approval/page`,
				method: 'POST',
				body,
			}),
			providesTags: ['ApplicationList'],
		}),

		getPageCount: builder.query<SuccessResponse<pageCountItem>, pageCountDTO>({
			query: (body) => ({
				url: `/web/approval/page-count`,
				method: 'POST',
				body,
			}),
			providesTags: ['ApplicationCount'],
		}),

		joinCentre: builder.mutation<SuccessResponse<any>, applyDTO>({
			query: (body) => ({
				url: `/web/approval/join-centre`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['ApplicationList', 'ApplicationCount'],
		}),
		outCentre: builder.mutation<SuccessResponse<any>, applyDTO>({
			query: (body) => ({
				url: `/web/approval/out-centre`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['ApplicationList', 'ApplicationCount'],
		}),
		joinMember: builder.mutation<SuccessResponse<any>, applyDTO>({
			query: (body) => ({
				url: `/web/approval/join-member`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['ApplicationList', 'ApplicationCount'],
		}),
		outMember: builder.mutation<SuccessResponse<any>, applyDTO>({
			query: (body) => ({
				url: `/web/approval/out-member`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['ApplicationList', 'ApplicationCount'],
		}),
	}),
});

export const {
	useGetApplicationListQuery,
	useGetApplicationInfoQuery,
	useGetPageCountQuery,
	useJoinCentreMutation,
	useOutCentreMutation,
	useJoinMemberMutation,
	useOutMemberMutation,
} = staffApiSlice;
