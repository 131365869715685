import {
	Box,
	Button,
	Card,
	CardContent,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Grid,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Dispatch, useContext } from 'react';
import Close from '@mui/icons-material/Close';
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import {
	useGetApplicationInfoQuery,
	useJoinCentreMutation,
	useOutCentreMutation,
	useJoinMemberMutation,
	useOutMemberMutation,
} from '@/api/user/applicationApiSlice';
import DoneIcon from '@mui/icons-material/Done';
import { ApplyEvent } from '@/api/enum/common.enum';
import JoinMemberDetailTop from './joinMemberDetailTop';
import JoinCentreDetailTop from './joinCentreDetailTop';
import OutCentreDetailTop from './outCentreDetailTop';
import OutMemberDetailTop from './outMemberDetailTop';
import { ApplicationContext } from '@/context/applicationContext';
import { genderOptions } from '@/api/enum/gender.enum';
import ConfirmationDialog from '@/common/components/ConfirmationDialog/ConfirmationDialog';
import useModal from '@/common/components/ConfirmationDialog/useModal';

interface DetailDialogProps {
	isOpen: boolean;
	setIsOpen: Dispatch<React.SetStateAction<boolean>>;
	type: ApplyEvent;
	statusMap?: any;
	detailObj: any;
}

const DetailDialog = (props: DetailDialogProps) => {
	const { isOpen, setIsOpen, type, statusMap, detailObj } = props;
	const { t } = useTranslation();
	const showModal = useModal();
	const [applyJoinCentre] = useJoinCentreMutation();
	const [applyOutCentre] = useOutCentreMutation();
	const [applyJoinMember] = useJoinMemberMutation();
	const [applyOutMember] = useOutMemberMutation();
	const { userRoles } = useContext(ApplicationContext);

	const userTypeOptions = [
		{
			value: 1,
			name: t('common.normalMember'),
		},
		{
			value: 2,
			name: t('common.programMember'),
		},
		{
			value: 3,
			name: t('common.communityMember'),
		},
	];

	const defaultValuePass = {
		transferId: detailObj.transferId,
		isApproved: true,
	};

	const defaultValueReject = {
		transferId: detailObj.transferId,
		isApproved: false,
	};

	const { data: resData } = useGetApplicationInfoQuery(detailObj.transferId, {
		refetchOnMountOrArgChange: true,
	});

	const formData = {
		...resData?.data,
		genderLabel:
			genderOptions.find((option) => option.id == resData?.data.gender)?.name ??
			'',
		userTypeLabel: userTypeOptions.find(
			(item) => item.value == resData?.data.userType
		)?.name,
	};

	const typeConfig = {
		[ApplyEvent.joinCentre]: {
			confirmContent: {
				pass: t('application.passJoinCentreApply'),
				reject: t('application.rejectJoinCentreApply'),
			},
			snackbar: {
				pass: t('snackbar.passJoinCentreApply'),
				reject: t('snackbar.rejectJoinCentreApply'),
			},
			api: {
				pass: applyJoinCentre,
				reject: applyJoinCentre,
			},
			dialogTitle: t('common.joinCentreApplicaionDetail'),
			detailTopElement: <JoinCentreDetailTop formData={formData} />,
			showStatus: false,
			showServiceNumber: false,
			isPassPermission: userRoles.passJoinCentre,
			isRejectPermission: userRoles.rejectJoinCentre,
		},
		[ApplyEvent.outCentre]: {
			confirmContent: {
				pass: t('application.passOutCentreApply'),
				reject: t('application.rejectOutCentreApply'),
			},
			snackbar: {
				pass: t('snackbar.passOutCentreApply'),
				reject: t('snackbar.rejectOutCentreApply'),
			},
			api: {
				pass: applyOutCentre,
				reject: applyOutCentre,
			},
			dialogTitle: t('common.outCentreApplicaionDetail'),
			detailTopElement: <OutCentreDetailTop formData={formData} />,
			showStatus: true,
			showServiceNumber: true,
			isPassPermission: userRoles.passOutCentre,
			isRejectPermission: userRoles.rejectOutCentre,
		},
		[ApplyEvent.joinMember]: {
			confirmContent: {
				pass: t('application.passJoinMemberApply'),
				reject: t('application.rejectJoinMemberApply'),
			},
			snackbar: {
				pass: t('snackbar.passJoinMemberApply'),
				reject: t('snackbar.rejectJoinMemberApply'),
			},
			api: {
				pass: applyJoinMember,
				reject: applyJoinMember,
			},
			dialogTitle: t('common.joinMemberApplicaionDetail'),
			detailTopElement: <JoinMemberDetailTop formData={formData} />,
			showStatus: false,
			showServiceNumber: false,
			isPassPermission: userRoles.passJoinMember,
			isRejectPermission: userRoles.rejectJoinMember,
		},
		[ApplyEvent.outMember]: {
			confirmContent: {
				pass: t('application.passOutMemberApply'),
				reject: t('application.rejectOutMemberApply'),
			},
			snackbar: {
				pass: t('snackbar.passOutMemberApply'),
				reject: t('snackbar.rejectOutMemberApply'),
			},
			api: {
				pass: applyOutMember,
				reject: applyOutMember,
			},
			dialogTitle: t('common.outMemberApplicaionDetail'),
			detailTopElement: <OutMemberDetailTop formData={formData} />,
			showStatus: false,
			showServiceNumber: false,
			isPassPermission: userRoles.passOutMember,
			isRejectPermission: userRoles.rejectOutMember,
		},
	};

	const handleClose = () => {
		setIsOpen(false);
	};
	const handlePass = () => {
		showModal((props: any) => (
			<ConfirmationDialog
				{...props}
				showCloseIcon={true}
				title={t('common.tip')}
				titleProps={{ variant: 'h6' }}
				description={
					<>
						<Typography variant='body1'>
							{typeConfig[type]?.confirmContent.pass}
						</Typography>
					</>
				}
				cancellationText={t('button.cancel')}
				confirmationText={t('button.sure')}
				confirmationButtonProps={{ variant: 'contained' }}
				onConfirm={async () => {
					const response = await typeConfig[type].api.pass(defaultValuePass);
					if ('data' in response) {
						enqueueSnackbar(
							`${formData?.username} ${typeConfig[type]?.snackbar.pass}`,
							{
								variant: 'success',
								anchorOrigin: { horizontal: 'center', vertical: 'top' },
								autoHideDuration: 3000,
								action: (key) => {
									return <CloseIcon onClick={() => closeSnackbar(key)} />;
								},
							}
						);
						handleClose();
					}
				}}
			/>
		));
	};
	const handleReject = async () => {
		showModal((props: any) => (
			<ConfirmationDialog
				{...props}
				showCloseIcon={true}
				title={t('common.tip')}
				titleProps={{ variant: 'h6' }}
				description={
					<>
						<Typography variant='body1'>
							{typeConfig[type]?.confirmContent.reject}
						</Typography>
					</>
				}
				cancellationText={t('button.cancel')}
				confirmationText={t('button.sure')}
				confirmationButtonProps={{ variant: 'contained' }}
				onConfirm={async () => {
					const response = await typeConfig[type].api.reject(
						defaultValueReject
					);
					if ('data' in response) {
						enqueueSnackbar(
							`${formData?.username} ${typeConfig[type]?.snackbar.reject}`,
							{
								variant: 'error',
								anchorOrigin: { horizontal: 'center', vertical: 'top' },
								autoHideDuration: 3000,
								action: (key) => {
									return <CloseIcon onClick={() => closeSnackbar(key)} />;
								},
							}
						);
						handleClose();
					}
				}}
			/>
		));
	};

	return (
		<>
			<Dialog open={isOpen} onClose={handleClose} maxWidth='md' fullWidth>
				<DialogTitle variant='h5'>{typeConfig[type]?.dialogTitle}</DialogTitle>
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
				<DialogContent>
					<Card>
						<CardContent
							sx={{ padding: '16px', paddingBottom: '16px!important' }}
						>
							<Grid container>
								<Box
									sx={{
										backgroundColor: 'grey.400',
										width: '80px',
										height: '80px',
										borderRadius: '50%',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										position: 'relative',
										marginRight: '40px',
									}}
								>
									<AccountCircleOutlinedIcon
										sx={{
											width: '60px',
											height: '60px',
											color: 'white',
										}}
									/>
									{formData?.userType && (
										<Chip
											label={formData?.userTypeLabel}
											color={
												formData?.userType == 1
													? 'chipFilledTertiary'
													: 'chipPIRUser'
											}
											sx={{
												position: 'absolute',
												top: 0,
												left: 45,
											}}
										/>
									)}
								</Box>
								<Grid xs item>
									{typeConfig[type].detailTopElement}
								</Grid>
							</Grid>
						</CardContent>
					</Card>
					<Grid container marginTop={'16px'}>
						{typeConfig[type]?.showStatus && (
							<>
								<Grid item xs={1.5} marginBottom={'10px'}>
									<Typography variant='subtitle1'>
										{t('common.outStatus')}
									</Typography>
								</Grid>
								<Grid item xs={10.5}>
									<Chip
										label={detailObj.status && statusMap[detailObj.status].name}
										color={
											detailObj.status && statusMap[detailObj.status].chipColor
										}
										variant='outlined'
									/>
								</Grid>
							</>
						)}

						<Grid item xs={6}>
							<Typography variant='subtitle1'>
								{t('common.accountInfo')}
							</Typography>
							<Grid container marginTop={'16px'}>
								<Grid item xs={3}>
									<Typography variant='body2' color='#5D5E61'>
										{t('common.username')}
									</Typography>
								</Grid>
								<Grid item xs={9}>
									<Typography variant='body2'>
										{formData?.username || '-'}
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography variant='body2' color='#5D5E61'>
										{t('common.phoneNumber')}
									</Typography>
								</Grid>
								<Grid item xs={9}>
									<Typography variant='body2'>
										{formData?.phone || '-'}
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography variant='body2' color='#5D5E61'>
										{t('common.email')}
									</Typography>
								</Grid>
								<Grid item xs={9}>
									<Typography variant='body2'>
										{formData?.email || '-'}
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography variant='body2' color='#5D5E61'>
										{t('common.belongedDistrict')}
									</Typography>
								</Grid>
								<Grid item xs={9}>
									<Typography variant='body2'>
										{formData?.fromDistrict || '-'}
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography variant='body2' color='#5D5E61'>
										{t('common.userCategory')}
									</Typography>
								</Grid>
								<Grid item xs={9}>
									<Typography variant='body2'>
										{formData?.userTypeLabel || '-'}
									</Typography>
								</Grid>
								{typeConfig[type]?.showServiceNumber && (
									<>
										<Grid item xs={3}>
											<Typography variant='body2' color='#5D5E61'>
												{t('common.historyServiceNumber')}
											</Typography>
										</Grid>
										<Grid item xs={9}>
											<Typography variant='body2'>
												{formData?.serviceNumber || '-'}
											</Typography>
										</Grid>
									</>
								)}
							</Grid>
						</Grid>
						<Grid item xs={6}>
							<Typography variant='subtitle1'>
								{t('common.personalInfo')}
							</Typography>
							<Grid container marginTop={'16px'}>
								<Grid item xs={3}>
									<Typography variant='body2' color='#5D5E61'>
										{t('common.nickname')}
									</Typography>
								</Grid>
								<Grid item xs={9}>
									<Typography variant='body2'>
										{formData?.nickname || '-'}
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography variant='body2' color='#5D5E61'>
										{t('common.gender')}
									</Typography>
								</Grid>
								<Grid item xs={9}>
									<Typography variant='body2'>
										{formData?.genderLabel || '-'}
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography variant='body2' color='#5D5E61'>
										{t('common.dateOfBirth')}
									</Typography>
								</Grid>
								<Grid item xs={9}>
									<Typography variant='body2'>
										{formData?.birthday || '-'}
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography variant='body2' color='#5D5E61'>
										{t('common.personalizedWords')}
									</Typography>
								</Grid>
								<Grid item xs={9}>
									<Typography variant='body2'>
										{formData?.signature || '-'}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
				{detailObj.status == 1 && (
					<DialogActions sx={{ padding: '20px' }}>
						{typeConfig[type]?.isRejectPermission && (
							<Button
								color='error'
								onClick={handleReject}
								startIcon={<CloseIcon />}
							>
								{t('button.reject')}
							</Button>
						)}
						{typeConfig[type]?.isPassPermission && (
							<Button
								color='success'
								variant='contained'
								onClick={handlePass}
								startIcon={<DoneIcon />}
							>
								{t('button.confirm')}
							</Button>
						)}
					</DialogActions>
				)}
			</Dialog>
		</>
	);
};

export default DetailDialog;
