export enum ApplyEvent {
	joinMember = 1,
	outMember = 2,
	joinCentre = 3,
	outCentre = 4,
}

export enum ArticleCategory {
	latestNews = 1,
	knowMentalHealth = 2,
	storyShare = 3,
	columnShare = 4,
}

export enum ArticleMenu {
	latestNews = 'latest-news',
	knowMentalHealth = 'know-mental-health',
	storyShare = 'story-share',
	columnShare = 'column-share',
}

export enum ArticlestatusType {
	draft = 1,
	directorApproval = 2,
	supervisorApproval = 3,
	awaitingRelease = 4,
	released = 5,
	removed = 6,
}

export enum CommunityResourcesType {
	draft = 1,
	directorApproval = 2,
	supervisorApproval = 3,
	awaitingRelease = 4,
	released = 5,
	removed = 6,
}
