import { useGetCentreDistrictsQuery } from '@/api/area/areaApiSlice';
import { userStatusOptions } from '@/api/enum/userStatus.enum';
import { userTypeOptions } from '@/api/enum/userType.enum';
import { useGetStaffListQuery } from '@/api/staff/staffApiSlice';
import { selectMyInfo } from '@/pages/auth/authSlice';
import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined';
import { Button, Grid, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AppMultipleDropDownMenu from '../../../../common/components/form-inputs/AppMultipleDropDownMenu';
import { OrgUserPageReqDTO } from '@/api/DTO/survey/survey.interface';

export default function AddMemberTargetSummaryFilterSection() {
	const { t } = useTranslation();
	const { control, watch, resetField, setValue } =
		useFormContext<OrgUserPageReqDTO>();

	const myInfo = useSelector(selectMyInfo);

	const { data: getStaffList } = useGetStaffListQuery(undefined, {
		refetchOnMountOrArgChange: true,
	});
	const { data: centreDistricts, isSuccess: isGetCentreDistrictsSuccess } =
		useGetCentreDistrictsQuery(myInfo?.centre || '', {
			refetchOnMountOrArgChange: true,
			skip: !myInfo?.centre,
		});

	const resetFilterRHFState = () => {
		resetField('loginID');
		setValue('userTypes', []);
		setValue('districtIds', []);
		setValue('statuses', []);
		setValue('staffIds', []);
	};

	const clearAllFilter = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		event.preventDefault();
		resetFilterRHFState();
	};

	// reset button
	const watchLoginId = watch('loginID');
	const watchUserTypes = watch('userTypes');
	const watchStatuses = watch('statuses');
	const watchDistrictIds = watch('districtIds');
	const watchStaffIds = watch('staffIds');

	const isLoginIdDirty = watchLoginId;
	const isUserTypesDirty = watchUserTypes && watchUserTypes.length > 0;
	const isStatusDirty = watchStatuses && watchStatuses.length > 0;
	const isDistrictIdsDirty = watchDistrictIds && watchDistrictIds.length > 0;
	const isStaffIdsDirty = watchStaffIds && watchStaffIds.length > 0;
	const isFilterDirty =
		isLoginIdDirty ||
		isUserTypesDirty ||
		isStatusDirty ||
		isDistrictIdsDirty ||
		isStaffIdsDirty;

	return (
		<Stack spacing={2}>
			<Grid container spacing={3}>
				<Grid item xs>
					<AppMultipleDropDownMenu
						control={control}
						name='districtIds'
						label={t('common.belongedDistrict')}
						options={centreDistricts?.data ?? []}
						size='small'
						emptyArrayIfNoSelection
					/>
				</Grid>
				<Grid item xs>
					<AppMultipleDropDownMenu
						control={control}
						name='userTypes'
						label={t('common.userCategory')}
						options={userTypeOptions}
						size='small'
						emptyArrayIfNoSelection
					/>
				</Grid>
				<Grid item xs>
					<AppMultipleDropDownMenu
						control={control}
						name='statuses'
						label={t('common.status')}
						options={userStatusOptions}
						size='small'
						emptyArrayIfNoSelection
					/>
				</Grid>

				<Grid item xs>
					<AppMultipleDropDownMenu
						control={control}
						name='staffIds'
						label={t('common.staffInCharge')}
						options={getStaffList?.data ?? []}
						size='small'
						emptyArrayIfNoSelection
					/>
				</Grid>

				<Grid item xs>
					<Button
						variant='text'
						startIcon={<RotateLeftOutlinedIcon />}
						disabled={!isFilterDirty}
						onClick={clearAllFilter}
					>
						{t('button.reset')}
					</Button>
				</Grid>
			</Grid>
		</Stack>
	);
}
