import { Outlet } from 'react-router-dom';
import { ThemeProvider, CssBaseline, styled } from '@mui/material';
import mainTheme from './theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import ModalRoot from './common/components/modal/ModalRoot';
import SnackbarRoot from './common/components/snackbar/SnackbarRoot';
import FullScreenLoaderRoot from './common/components/fullScreenLoader/FullScreenLoaderRoot';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import VersionUpdateAlertSnackbarRoot from './common/components/snackbar/UpdateAlertSnackbarRoot';
import { SnackbarProvider, MaterialDesignContent } from 'notistack';
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';
import { ConfirmProvider } from 'material-ui-confirm';
import WarningAmber from '@mui/icons-material/WarningAmber';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ModalProvider from './common/components/ConfirmationDialog/ModalProvider';

require('@/utils/dayjs_locale/zh-hk');

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
	'&.notistack-MuiContent-success': {
		backgroundColor: '#EDF7ED',
		color: '#1E4620',
	},
	'&.notistack-MuiContent-error': {
		backgroundColor: '#FDEDED',
		color: '#5F2120',
		'.MuiSvgIcon-fontSizeMedium': {
			color: '#D32F2F',
		},
	},
	// '&.notistack-MuiContent-default': {
	// 	backgroundColor: '#970C0C', // 使用默認中。設計圖中沒找到相關css，待配置
	// },
	'&.notistack-MuiContent-warning': {
		backgroundColor: '#FFF4E5',
		color: '#663C00',
		'.MuiSvgIcon-fontSizeMedium': {
			color: '#EF6C00',
		},
	},
}));

function App() {
	return (
		<ThemeProvider theme={mainTheme}>
			<SnackbarProvider
				maxSnack={3}
				Components={{
					default: StyledMaterialDesignContent,
					success: StyledMaterialDesignContent,
					error: StyledMaterialDesignContent,
					warning: StyledMaterialDesignContent,
					info: StyledMaterialDesignContent,
				}}
				iconVariant={{
					success: <CheckCircleOutlined sx={{ marginRight: '15px' }} />,
					warning: <WarningAmber sx={{ marginRight: '15px' }} />,
					error: <ErrorOutlineIcon sx={{ marginRight: '15px' }} />,
					// 使用默認中。default error warning info待配置
				}}
			>
				<ConfirmProvider>
					<ModalProvider>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<CssBaseline />
							<Outlet />
							<ModalRoot />
							<SnackbarRoot />
							<FullScreenLoaderRoot />
							<VersionUpdateAlertSnackbarRoot />
						</LocalizationProvider>
					</ModalProvider>
				</ConfirmProvider>
			</SnackbarProvider>
		</ThemeProvider>
	);
}

export default App;
